import React, { Component } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseAuthProvider, FirebaseAuthConsumer, IfFirebaseAuthedAnd } from '@react-firebase/auth';
import Button from '../../common/Button';
import { firebaseConfig } from '../../firebase.config';
import { connect } from 'react-redux';
import { saveFirebaseSettings, getFirebaseSettings, resetThemeSettings } from '../Settings/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { selectIsFirebaseDataLoading } from '../Settings/selectors';
import { Alert } from 'react-bootstrap';
import { AlertContainer } from '../../common/AlertContainer';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  color: ${({ theme }) => theme.color};
`;

const SignInButton = styled(Button)`
  margin: 20px;
  height: 100%;
`;

class index extends Component {
  state = {
    alertDetails: {
      showAlert: false,
      variant: 'primary',
      message: 'initial message',
    },
    isFirebaseDataLoading: true,
  };
  signOut() {
    firebase.auth().signOut();
  }

  componentDidMount() {
    this.subscribeToAuthChange();
  }

  componentDidUpdate(prevProps) {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
      } else {
        // No user is signed in.
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    // this.setState({ isFirebaseDataLoading: nextProps.isFirebaseDataLoading });
    if (this.state.isFirebaseDataLoading !== nextProps.isFirebaseDataLoading) {
      this.setState({ isFirebaseDataLoading: nextProps.isFirebaseDataLoading });
    }
  }

  //   // console.log(
  //   //   `${this.props.isFirebaseDataLoading} '!==' ${nextProps.isFirebaseDataLoading}`,
  //   // );
  //   // console.log(
  //   //   `${this.props.isFirebaseDataLoading !== nextProps.isFirebaseDataLoading}`,
  //   // );
  //   // return nextProps.isFirebaseDataLoading !== nextState.isFirebaseDataLoading;
  //   // return true;
  // }

  subscribeToAuthChange = () => {
    const scope = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        scope.props.getSettings(user.uid);
      } else {
        // No user is signed in.
      }
    });
  };

  activateAlert = (variant, message) => {
    this.setState({
      alertDetails: {
        variant,
        message,
        showAlert: true,
      },
    });

    setTimeout(() => {
      this.setState({
        alertDetails: {
          variant: 'success',
          message: '',
          showAlert: false,
        },
      });
    }, 3500);
  };

  render() {
    return (
      <FirebaseAuthProvider style={{ width: '600px' }} {...firebaseConfig} firebase={firebase}>
        <Container>
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              return !isSignedIn ? (
                <SignInButton
                  variant="outline-secondary"
                  onClick={async () => {
                    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                    await firebase.auth().signInWithPopup(googleAuthProvider);
                  }}
                >
                  <FontAwesomeIcon icon={faGoogle} size="1x" />

                  <FormattedMessage {...messages.signIn} />
                </SignInButton>
              ) : (
                <>
                  <SignInButton
                    onClick={() => {
                      firebase.auth().signOut();
                      this.props.resetTheme();
                    }}
                  >
                    <FormattedMessage {...messages.signOut} />
                  </SignInButton>
                  <IfFirebaseAuthedAnd filter={({ providerId }) => providerId !== 'anonymous'}>
                    {({ providerId, user }) => {
                      return (
                        // <Container>
                        // Hello, {user.displayName}
                        <SignInButton
                          onClick={() => {
                            this.props.saveSettings(user.uid);
                            this.activateAlert('success', <FormattedMessage {...messages.settingsSavedAlert} />);
                          }}
                          primary
                          isLoading={this.state.isFirebaseDataLoading}
                        >
                          <FormattedMessage {...messages.saveSettings} />
                        </SignInButton>
                      );
                    }}
                  </IfFirebaseAuthedAnd>
                </>
              );
            }}
          </FirebaseAuthConsumer>
        </Container>
        <AlertContainer showAlert={this.state.alertDetails.showAlert}>
          <Alert variant={this.state.alertDetails.variant}>{this.state.alertDetails.message}</Alert>
        </AlertContainer>
      </FirebaseAuthProvider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveSettings: userId => dispatch(saveFirebaseSettings(userId)),
  getSettings: userId => dispatch(getFirebaseSettings(userId)),
  resetTheme: () => dispatch(resetThemeSettings()),
  //TODO: Set user info...
  // setUserInfo: () => dispatch(setUserInfo()),
});

const mapStateToProps = state => ({
  isFirebaseDataLoading: selectIsFirebaseDataLoading(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
