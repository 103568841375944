import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { slideInRight } from 'react-animations';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setProjectViewIndex } from './actions';
import { selectProjectViewIndex } from './selectors';
import { connect } from 'react-redux';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const animation = keyframes`${slideInRight}`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0px auto;
  animation: 1s ${animation};
  color: ${({ theme }) => theme.color};
`;

const IndicatorContainer = styled.div`
  display: flex;
`;

const PulsingArrow = styled.div`
  padding-top: 8px;
  animation: blinker 0.6s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

  @keyframes blinker {
    to {
      opacity: 0.6;
      transform: translate3d(-7px, 0, 0);
    }
  }

  @media (max-width: 1000px) {
    padding-top: 30px;
  }
`;

class index extends Component {
  render() {
    return (
      <Container>
        <DarkmodeReduxSwitch />
        <PulsingArrow>
          <IndicatorContainer>
            <FontAwesomeIcon icon={faHandPointLeft} size="2x"></FontAwesomeIcon>
            <div style={{ alignSelf: 'center', marginLeft: '8px' }}>
              <h6>
                <FormattedMessage {...messages.clickHere} />
              </h6>
            </div>
          </IndicatorContainer>
        </PulsingArrow>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setProjectIndex: index => dispatch(setProjectViewIndex(index)),
});

const mapStateToProps = state => ({
  selectProjectIndex: selectProjectViewIndex(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
