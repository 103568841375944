import styles from './TimeSlot.module.css';
import React from 'react';
import styled from 'styled-components';
import { formatTime } from '../../utils/time_helper';
import { BoxShadow } from '../../common';
import Button from '../../common/Button';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const CardContainer = styled.div`
  display: flex;
  min-height: 130px;
  max-height: 400px;
  min-width: 200px;
  max-width: 500px;
  box-shadow: ${BoxShadow};
  padding: 10px;
  margin: 15px auto;
  box-sizing: border-box;
  list-style: none;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
`;

const ContentWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  min-height: 130px;
  max-height: 400px;
  min-width: 200px;
  max-width: 500px;
`;

const StyledButton = styled(Button)`
  max-height: 35px;
  width: 60px;
  margin-left: 15px;
`;

const index = props => {
  let id = props.details;
  let hasAppointment = props.timeSlotData[id].reserved ? true : false;

  return (
    <CardContainer className={hasAppointment ? styles.Reserved : styles.Open}>
      <ContentWrapper className="container d-flex">
        <div className="row">
          <div className="col">
            <FormattedMessage {...messages.timeSlotFrom} />
            <strong>{formatTime(props.details)}</strong>
            <FormattedMessage {...messages.to} />
            <strong>{formatTime(+props.details + 1)}</strong>
            <br />
            <br />
            {hasAppointment ? (
              <div>
                <h5>
                  <FormattedMessage {...messages.reservedBy} />

                  {props.timeSlotData[id].person.name}
                </h5>
                <h6>{props.timeSlotData[id].person.phone}</h6>
              </div>
            ) : (
              <h5>
                <FormattedMessage {...messages.available} />
              </h5>
            )}
          </div>
        </div>
        <StyledButton primary onClick={() => props.itemClicked(props.details)} type="button">
          <FormattedMessage {...messages.select} />
        </StyledButton>
      </ContentWrapper>
    </CardContainer>
  );
};

export default index;
