import { defineMessages } from 'react-intl';

export default defineMessages({
  timeSlotFrom: {
    id: 'TimeSlotCard.timeSlotFrom',
    defaultMessage: 'Time slot from ',
  },
  reservedBy: {
    id: 'TimeSlotCard.reservedBy',
    defaultMessage: 'Reserved by ',
  },
  available: {
    id: 'TimeSlotCard.available',
    defaultMessage: 'Available',
  },
  select: {
    id: 'TimeSlotCard.select',
    defaultMessage: 'Select',
  },
  to: {
    id: 'TimeSlotCard.to',
    defaultMessage: ' to ',
  },
});
