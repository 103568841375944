import { SET_GALLERY_INDEX } from '../components/GalleriesHome/constants';

/*
 src/reducers/simpleReducer.js
*/
export default (state = {}, action) => {
  switch (action.type) {
    case SET_GALLERY_INDEX:
      return {
        selectedIndex: action.payload,
      };
    default:
      return state;
  }
};
