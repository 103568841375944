import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { slideInRight } from 'react-animations';
import stickman from '../../resources/stickman.png';
import stickman1 from '../../resources/stickman_run_1.png';
import stickman2 from '../../resources/stickman_run_2.png';
import stickman3 from '../../resources/stickman_run_3.png';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const BuddyMessages = [
  <FormattedMessage {...messages.hello} />,
  <FormattedMessage {...messages.hey} />,
  <FormattedMessage {...messages.cutItOut} />,
  <FormattedMessage {...messages.stop} />,
  <FormattedMessage {...messages.quit} />,
  <FormattedMessage {...messages.goAway} />,
  <FormattedMessage {...messages.why} />,
];

const animation = keyframes`${slideInRight}`;

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  z-index: 20;
  width: 100%;
  margin: 0px 10px auto;
  animation: 1s ${animation};

  @media (max-width: 650px) {
    display: none;
  }
`;

const StickManArena = styled.div`
  width: 100%;
`;
const ToolTipText = styled.div`
  visibility: ${props => (props.isRunning ? 'hidden' : 'visibile')};
  opacity: ${props => (props.isRunning ? '0' : '1')};
  display: flex;
  min-width: 120px;
  max-width: 200px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: #dcdcdc;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: relative;
  transition: all 700ms ease-in-out;
`;

const StickManContainer = styled.div`
  display: flex;
  height: 200px;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1250ms ease-in-out;
  transition: ${props => (props.isInitialLoad ? 'all 5000ms ease-in-out' : 'all 1250ms ease-in-out')};
  margin-top: ${props => props.top + 'px'};
  margin-right: ${props => props.right + 'px'};
  margin-bottom: ${props => props.bottom + 'px'};
  margin-left: ${props => props.left + 'px'};

  div {
    transition: all 500ms ease-in-out;
  }
`;

const StickManImage = styled.img`
  height: 100px;
  transition: all 200ms ease-in-out;

  transform: ${props => (props.direction === 'left' ? 'scaleX(-1)' : 'scaleX(1)')};
`;

class index extends Component {
  state = {
    top: 150,
    right: 0,
    bottom: 0,
    left: 1500,
    prevTop: 0,
    prevRight: 0,
    prevBottom: 0,
    prevLeft: 0,
    stickManImg: stickman,
    currentCount: 25,
    isRunning: true,
    direction: 'left',
    isVisible: true,
    isInitialLoad: true,
    currentPicIndex: 0,
    currentMessage: BuddyMessages[0],
  };

  componentDidMount() {
    this.sendInBuddyInterval = setInterval(this.sendInBuddy.bind(this), 200);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.sendInBuddyInterval);
  }

  sendInBuddy() {
    this.setState({ left: 0 });

    this.setState(prevState => ({
      currentCount: prevState.currentCount - 1,
      isVisible: true,
    }));

    if (this.state.currentPicIndex === 0) {
      this.setState({ stickManImg: stickman1, currentPicIndex: 1 });
    } else if (this.state.currentPicIndex === 1) {
      this.setState({ stickManImg: stickman2, currentPicIndex: 2 });
    } else if (this.state.currentPicIndex === 2) {
      this.setState({ stickManImg: stickman3, currentPicIndex: 0 });
    }

    if (this.state.currentCount < 1) {
      this.setState({
        stickManImg: stickman,
        isRunning: false,
        currentCount: 10,
      });
      clearInterval(this.sendInBuddyInterval);
    }
  }

  timer() {
    this.setState(prevState => ({
      currentCount: prevState.currentCount - 1,
    }));

    if ([10, 7, 4].includes(this.state.currentCount)) {
      this.setState({ stickManImg: stickman1 });
    } else if ([9, 6, 3].includes(this.state.currentCount)) {
      this.setState({ stickManImg: stickman2 });
    } else if ([8, 7, 6].includes(this.state.currentCount)) {
      this.setState({ stickManImg: stickman3 });
    } else {
      this.setState({ stickManImg: stickman });
    }

    if (this.state.currentCount < 1) {
      clearInterval(this.intervalId);
      this.setState({
        currentCount: 10,
        isRunning: false,
        currentMessage: BuddyMessages[Math.floor(Math.random() * BuddyMessages.length)],
      });
      return;
    }
  }

  onMouseEnter = event => {
    if (this.state.isRunning) {
      return;
    }
    clearInterval(this.intervalId);

    this.setState({ currentCount: 10, isRunning: true, isInitialLoad: false });
    this.intervalId = setInterval(this.timer.bind(this), 125);

    var randTop = Math.floor(Math.random() * (window.innerHeight - 100));
    var randLeft = Math.floor(Math.random() * (window.innerWidth - 100));
    var randBottom = Math.floor(Math.random() * (window.innerHeight - 400));
    var randRight = Math.floor(Math.random() * (window.innerWidth - 100));
    if (randLeft > this.state.prevLeft) {
      this.setState({
        direction: 'right',
      });
    } else {
      this.setState({
        direction: 'left',
      });
    }

    this.setState({
      top: randTop,
      prevTop: randTop,
      right: randRight,
      prevRight: randRight,
      bottom: randBottom,
      prevBottom: randBottom,
      left: randLeft,
      prevLeft: randLeft,
    });
  };

  render() {
    const { isRunning, currentMessage, direction, top, right, bottom, left, stickManImg, isInitialLoad, isVisible } = { ...this.state };
    return (
      <Container>
        <StickManArena>
          {isVisible && (
            <StickManContainer
              onMouseEnter={this.onMouseEnter}
              top={top}
              right={right}
              bottom={bottom}
              left={left}
              isInitialLoad={isInitialLoad}
              onMouseOver={this.onMouseEnter}
              onClick={this.onMouseEnter}
            >
              <ToolTipText isRunning={isRunning}>{currentMessage}</ToolTipText>
              <StickManImage direction={direction} src={stickManImg} />
            </StickManContainer>
          )}
        </StickManArena>
        <br />
        <br />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
