import * as firebase from 'firebase/app';

export const saveSettings = async (userId, data) => {
  const db = firebase.firestore();
  try {
    db.collection('users')
      .doc(userId)
      .set(
        {
          ...data,
        },
        { merge: true },
      )
      .then(function () {
        // db.storage()
        //   .ref(`siteSettings/test`)
        //   .child();
        // scope.activateAlert('success', 'Document successfully written!');
        // scope.fetchGalleryData();
        // scope.setState({
        //   titleInputValue: 'New Gallery',
        //   srcValues: ['https://fakeimg.pl/440x320/282828/eae0d0/?retina=1'],
        // });
      })
      .catch(function (error) {
        // scope.activateAlert('danger', 'Error creating document!');
      });
  } catch (error) {
    // this.activateAlert('danger', 'Error creating document!');
  }
  // });
};

export const getSettings = async (userId) => {
  const db = firebase.firestore();

  var docRef = db.collection('users').doc(userId);

  return await docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        // console.log('Document data:', doc.data());
        return doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch(function (error) {
      console.log('Error getting document:', error);
    });
  // db.collection('users')
  //   .get(userId)
  //   // .then((res) => {
  //   //   console.log(res.data());
  //   //   // querySnapshot.forEach(function(doc) {
  //   //   //   const initialData = doc.data();
  //   //   //   const keys = Object.keys(initialData);
  //   //   //   const data = keys.map((key) => initialData[key]);
  //   //   // });
  // });

  // db.collection('users')
  //   .get()
  //   .then(function(querySnapshot) {
  //     if (querySnapshot.docs.length > 0) {
  //       querySnapshot.forEach(function(doc) {
  //         const initialData = doc.data();
  //         console.log(initialData);
  //         // const keys = Object.keys(initialData);
  //         // const data = keys.map((key) => initialData[key]);
  //         // const gallery = { name: [doc.id], data };
  //         // galleries.push({ ...gallery });
  //       });
  //     }
  //   });
};
