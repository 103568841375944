import styled from 'styled-components';
import React from 'react';
import ButtonSpinner from '../ButtonSpinner';

const Button = styled.button`
  background-color: ${props => (props.primary ? props.theme.main_button_primary : props.theme.bg)};
  color: ${props => (props.primary ? props.theme.main_button_color : props.theme.main_button_primary)};
  min-height: 40px;
  min-width: 90px;
  /* height: 100%; */
  font-size: 1em;
  padding: 0.25em 1em;
  outline: none;
  border: ${props => (props.primary ? `1px solid transparent` : `1px solid ${props.theme.main_button_primary}`)};
  border-radius: 3px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
/* TODO Change red to theme color */
  background-color: ${({ occupied }) => occupied ? 'red' : ''}
  &:hover {
    background-color: ${props => (props.primary ? props.theme.main_button_primary_darker : props.theme.main_button_primary_darker)};
    color: ${props => (props.primary ? 'white' : 'white')};
    border: ${props => (props.primary ? `1px solid ${props.theme.main_button_primary_darker}` : `1px solid ${props.theme.main_button_primary_darker}`)};
  }
  transition: 250ms ease all;
`;
const index = ({ isLoading, children, ...rest }) => {
  return <Button {...rest}>{isLoading ? <ButtonSpinner /> : children}</Button>;
};

export default index;
