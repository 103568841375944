/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { slideInRight } from 'react-animations';
import SingleGallery from '../SingleGallery';
import { getPlaceholderImages } from '../../utils/api_helper';
import { photos_Rayland, photos_Tara_and_Samuel } from '../../resources/images/photos';
import Spinner from '../../common/Spinner';
import { Link } from 'react-router-dom';
import { setGalleryIndex } from './actions';
import { connect } from 'react-redux';
import { selectGalleryIndex } from './selectors';
const animation = keyframes`${slideInRight}`;

const BouncyDiv = styled.div`
  animation: 1s ${animation};
`;
const GalleryCategoriesWrapper = styled.div`
  margin: 0px auto;
  margin-bottom: 6%;
  width: 85%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin-top: 100px;
  width: 1100px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-items: space-between;
`;

const TitleText = styled.div`
  color: white;
  font-size: 30px;
  text-decoration: none;
  text-underline-position: unset;
  color: white;
  padding: 16px 32px;
`;

const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

const Box = styled.div`
  position: relative;
  align-content: center;
  justify-content: center;
  background-color: #444;
  height: 250px;
  width: 250px;
  color: #fff;
  font-size: 150%;
  margin: auto;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  :hover {
    transform: scale(1.01);
    cursor: pointer;
  }

  &:hover img {
    opacity: 0.4;
  }

  &:hover ${Middle} {
    opacity: 1;
  }

  :nth-child(even) {
    background-color: #ccc;
    color: #000;
  }
`;

const GalleryBoxImage = styled.img`
  object-fit: cover;
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

class index extends Component {
  state = {
    selectedCategoryIndex: null,
    loading: false,
  };

  async componentDidMount() {
    this.setState({
      selectedCategoryIndex: null,
    });
  }

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width });
      img.onerror = reject;
      img.src = src;
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedCategoryIndex: null,
    });
  }

  async getImageDimensions(imageUrl) {
    const result = await this.addImageProcess(imageUrl);
    return result;
  }

  async handleSelectedCategory(selectedCategoryIndex) {
    this.props.setGalleryIndex(selectedCategoryIndex);
    this.setState({
      loading: true,
    });
    let photos = await getPlaceholderImages(20, `Category ${selectedCategoryIndex + 1}`);
    //Rayland...
    if (selectedCategoryIndex === 0) {
      photos = await Promise.all(
        photos_Rayland.map(async (photo, index) => {
          let dimensions = await this.getImageDimensions(photos_Rayland[index].src);
          const { height, width } = { ...dimensions };

          let result = {
            src: photo.src,
            width,
            height,
          };
          return result;
        })
      );
    }
    //Sam and Tara...
    if (selectedCategoryIndex === 1) {
      photos = await Promise.all(
        photos_Tara_and_Samuel.map(async (photo, index) => {
          let dimensions = await this.getImageDimensions(photos_Tara_and_Samuel[index].src);
          const { height, width } = { ...dimensions };

          let result = {
            src: photo.src,
            width,
            height,
          };
          return result;
        })
      );
    }

    this.setState({
      selectedCategoryIndex,
      loading: false,
      photos,
    });
  }

  render() {
    const { selectedCategoryIndex, loading } = { ...this.state };
    const Categories = Array.from({ length: 8 }, (_, i) => (
      <Box onClick={() => this.handleSelectedCategory(i)}>
        <Middle>
          <TitleText>{'Category ' + (i + 1)}</TitleText>
        </Middle>
      </Box>
    ));

    Categories[0] = (
      <Link to="/galleries/Rayland">
        <Box onClick={() => this.handleSelectedCategory(0)}>
          <GalleryBoxImage src={photos_Rayland[0].src} height={250} width={250} />
          <Middle>
            <TitleText>{'Rayland'}</TitleText>
          </Middle>
        </Box>
      </Link>
    );
    Categories[1] = (
      <Link to="/galleries/TaraAndSamuel">
        <Box onClick={() => this.handleSelectedCategory(1)}>
          <GalleryBoxImage src={photos_Tara_and_Samuel[0].src} height={250} width={250} />
          <Middle>
            <TitleText>{'Tara & Samuel'}</TitleText>
          </Middle>
          {/* <Middle>
            <TitleText>John Doe</TitleText>
          </Middle> */}
        </Box>
      </Link>
    );
    const Rayland = <SingleGallery photos={this.state.photos} />;
    return selectedCategoryIndex === null ? (
      <BouncyDiv>
        <GalleryCategoriesWrapper>
          <Wrapper>{Categories}</Wrapper>
        </GalleryCategoriesWrapper>
      </BouncyDiv>
    ) : loading === true ? (
      <Spinner />
    ) : (
      <div>{Rayland}</div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGalleryIndex: index => dispatch(setGalleryIndex(index)),
});

const mapStateToProps = state => ({
  selectGalleryIndex: selectGalleryIndex(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
