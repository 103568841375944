import { defineMessages } from 'react-intl';

export default defineMessages({
  information: {
    id: 'About.Information',
    defaultMessage:
      "I'm Chris, a results-driven Engineering Manager with 6 years of experience in leading full-stack development projects, specializing in scalable microservice architectures, cloud solutions, and ecommerce platforms. ",
  },
  aboutMe: {
    id: 'About.AboutMe',
    defaultMessage: 'About Me',
  },
  viewMyResume: {
    id: 'About.ViewMyResume',
    defaultMessage: 'View My Resume',
  },
  backendTitle: {
    id: 'About.BackendTitle',
    defaultMessage: 'Back End',
  },
  frontendTitle: {
    id: 'About.FrontendTitle',
    defaultMessage: 'Front End',
  },
  mobileTitle: {
    id: 'About.MobileTitle',
    defaultMessage: 'Mobile',
  },
  mobileDescription: {
    id: 'About.MobileDescription',
    defaultMessage:
      "I've developed multiple full-fledged applications for both iOS and Android using Swift, Java with Android Studios, and React-Native.",
  },
});
