import React, { Component } from 'react';
import * as actionTypes from './actions';
import { connect } from 'react-redux';
import TimeSlotCard from '../TimeSlotCard';
import styled from 'styled-components';
import { FadeAnimationNoStyles } from '../../common/Styles';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import AppointmentModal from '../AppointmentModal';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
let timeSlotList = [];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
`;
const Header = styled.h2`
  margin: 30px;
  color: ${({ theme }) => theme.color};
`;

class TimeSlots extends Component {
  state = {
    result: 0,
    showModal: false,
  };

  componentWillMount() {
    this.props.populateTimeSlotData();
  }

  toggleTimeSlotItemPressed = timeSlotData => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
    this.props.timeSlotItemPressed(timeSlotData);
  };

  setModalShow = showModal => {
    this.setState({ showModal });
  };

  saveTimeSlot = () => {};

  render() {
    let timeSlotItems = null;
    if (this.props.timeSlotData) {
      timeSlotItems = Object.keys(this.props.timeSlotData).map(item => (
        <TimeSlotCard key={item} details={item} timeSlotData={this.props.timeSlotData} itemClicked={this.toggleTimeSlotItemPressed}></TimeSlotCard>
      ));
    }
    return (
      <FadeAnimationNoStyles>
        <Container>
          <DarkmodeReduxSwitch />
          <div className="container-fluid d-flex mx-auto justify-content-center ">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <h5>A simple demonstration of form control and modals.</h5>
                  <Header>
                    <FormattedMessage {...messages.pleaseSelect} />
                  </Header>
                </div>

                {timeSlotList}
                {timeSlotItems}
                <AppointmentModal
                  show={this.state.showModal}
                  onHide={() => this.setModalShow(false)}
                  onNameChange={this.props.onNameChange}
                  timeSlotData={this.props.timeSlotData}
                  selectedTime={this.props.selected}
                  onPhoneChange={this.props.onPhoneChange}
                />
              </div>
            </div>
          </div>
        </Container>
      </FadeAnimationNoStyles>
    );
  }
}

const mapStateToProps = state => {
  return {
    res: state.AppointmentsReducer.result,
    endTime: state.AppointmentsReducer.endTime,
    timeSlotData: state.AppointmentsReducer.timeSlots,
    selected: state.AppointmentsReducer.selectedTimeSlot,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    populateTimeSlotData: () =>
      dispatch({
        type: actionTypes.POPULATE_INITIAL_DATA,
      }),
    onSubPressed: number =>
      dispatch({
        type: actionTypes.SUB,
        payload: number,
      }),
    timeSlotItemPressed: startTime =>
      dispatch({
        type: actionTypes.TIME_SLOT_ITEM_PRESSED,
        payload: startTime,
      }),
    onNameChange: text =>
      dispatch({
        type: actionTypes.ON_NAME_CHANGED,
        payload: {
          text: text,
        },
      }),
    onPhoneChange: text =>
      dispatch({
        type: actionTypes.ON_PHONE_CHANGED,
        payload: {
          text: text,
        },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSlots);
