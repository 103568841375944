import React from 'react';
import { connect } from 'react-redux';
import LandingPage from './components/LandingPage';
// Firebase App (the core Firebase SDK) is always required and must be listed first
// import firebase from 'firebase/app';
// import 'firebase/auth'; // Add the Firebase products that you want to use
// import 'firebase/auth';
// import 'firebase/firestore';

import 'firebase/auth';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import { firebaseConfig } from './firebase.config';

// firebase.initializeApp(firebaseConfig);

function App() {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default connect()(App);
