import React, { Component } from 'react';
import styled from 'styled-components';
import WeatherChart from '../WeatherChart';
import WeatherCard from '../WeatherCard';
import { fetchWeatherData, extractDetails } from './utils';
import Spinner from '../../common/Spinner';
import { FadeAnimation } from '../../common/Styles';
import { selectLanguage } from '../Settings/selectors';
import { connect } from 'react-redux';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
  text-align: center;
`;
const FiveDayLabel = styled.h4`
  margin: 20px;
`;
const ZipCodeInput = styled.input`
  margin-left: 15px;
`;
const WeatherUI = styled.div`
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.bg};
`;

const SpinnerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  margin-top: 40px;
`;

const WeatherCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px auto;
  width: 90%;
  background-color: ${({ theme }) => theme.bg_lighter};
  opacity: 0.7;
  box-shadow: ${({ theme }) => theme.box_shadow + ' ' + theme.shadow_color};
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 50px;
  @media (max-width: 580px) {
    width: 100%;
  }
`;

const StyledSpinner = styled(Spinner)`
  margin: 0px auto;
`;
const StyledError = styled.div`
  margin: 15px;
  font-size: 20px;
  color: darksalmon;
  font-weight: 900;
`;

class index extends Component {
  state = {
    forecastData: null,
    loading: false,
    zip: 30350,
    errorMessage: null,
    darkmode: true,
  };

  componentDidMount() {
    this.search(this.state.zip);
  }

  timeout = null;
  handleChange(event) {
    var zip = event.target.value;

    const validateNumbers = /^-?\d*\.?\d*$/;
    if (zip.length > 5 || !validateNumbers.test(zip)) {
      return;
    }
    this.setState({ zip });
    if (zip.length !== 5) {
      return;
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      async () => {
        this.setState({ loading: true, errorMessage: null });
        await this.search(zip);
      },
      750,
      zip
    );
  }

  async search(zip) {
    let response = await fetchWeatherData(zip, this.props.selectLanguage).catch(() => {
      this.setState({ loading: false, errorMessage: 'Invalid zipcode' });
      return;
    });
    if (this.state.errorMessage) {
      return;
    }
    const forecastData = extractDetails(response, this.props.selectLanguage);
    this.setState({ forecastData, loading: false });
  }

  toggleCheckBox = () => {
    this.setState(prevState => ({ darkmode: !prevState.darkmode }));
  };

  render() {
    const { loading, forecastData, zip, errorMessage, darkmode } = {
      ...this.state,
    };

    let weatherCards = null;
    if (forecastData) {
      weatherCards = forecastData.data.map(data => <WeatherCard data={data} city={forecastData.city} />);
    }
    return (
      <FadeAnimation>
        <Container darkmode={darkmode}>
          <DarkmodeReduxSwitch />
          <InputContainer darkmode={darkmode}>
            <FormattedMessage {...messages.zip} />
            <label>
              <ZipCodeInput value={zip} onChange={e => this.handleChange(e)} />
            </label>
          </InputContainer>
          {loading && (
            <SpinnerContainer>
              <StyledSpinner />
            </SpinnerContainer>
          )}
          <StyledError>{errorMessage && errorMessage}</StyledError>
          {this.state.forecastData && (
            <WeatherUI darkmode={darkmode}>
              <FiveDayLabel darkmode={darkmode}>
                {forecastData.city + " "}
                <FormattedMessage {...messages.fiveDayForecast} />
              </FiveDayLabel>

              <WeatherCardsContainer darkmode={darkmode}>{weatherCards}</WeatherCardsContainer>
              <div>
                <i>
                  <FormattedMessage {...messages.dependingOnTimeOfDay} />
                </i>
              </div>
              <WeatherChart darkmode={darkmode} forecastData={forecastData} />
            </WeatherUI>
          )}
        </Container>
      </FadeAnimation>
    );
  }
}

const mapStateToProps = state => ({
  selectLanguage: selectLanguage(state),
});

export default connect(
  mapStateToProps,
  null
)(index);
