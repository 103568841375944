import styled from 'styled-components';

export const AlertContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  opacity: ${({ showAlert }) => (showAlert ? 0.8 : 0.0)};
  transition: all 250ms ease;
`;
