import { defineMessages } from 'react-intl';

export default defineMessages({
  signIn: {
    id: 'SignInFirebase.signIn',
    defaultMessage: 'Sign In to save or load your settings',
  },
  signOut: {
    id: 'SignInFirebase.signOut',
    defaultMessage: 'Sign Out',
  },
  saveSettings: {
    id: 'SignInFirebase.saveSettings',
    defaultMessage: 'Save Settings',
  },
  settingsSavedAlert: {
    id: 'SignInFirebase.settingsSavedAlert',
    defaultMessage: 'Settings Saved',
  },
});
