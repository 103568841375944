/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FadeAnimationNoStyles } from '../../common/Styles';
import { ProjectList } from '../ProjectsPage/constants';
import { setProjectViewIndex } from '../ProjectsPage/actions';
import { selectProjectViewIndex } from '../ProjectsPage/selectors';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Header = styled.div`
  margin-top: 40px;
  color: ${({ theme }) => theme.color};
`;
const StyledFadeAnimation = styled(FadeAnimationNoStyles)`
  flex-direction: column;
  text-align: center;
  display: flex;
`;
const GalleryCategoriesWrapper = styled.div`
  text-align: center;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  padding: 6%;
  background-color: ${({ theme }) => theme.bg};
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color};
`;

const BottomPadding = styled.div`
  height: 100px;
`;

const ProjectLabel = styled.h5`
  text-align: center;
  overflow: auto;
`;
const ProjectThumbnailContainer = styled.div`
  text-align: center;
`;
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  justify-content: center;
`;

const TitleText = styled.div`
  color: white;
  font-size: 30px;
  text-decoration: none;
  text-underline-position: unset;
  color: white;
  padding: 16px 32px;
`;

const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

const Box = styled.div`
  position: relative;
  align-content: center;
  justify-content: center;
  background-color: #444;
  height: 250px;
  width: 250px;
  color: #fff;
  font-size: 150%;
  margin: auto;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);

  :hover {
    transform: scale(1.01);
    cursor: pointer;
  }

  &:hover img {
    opacity: 0.4;
  }

  &:hover ${Middle} {
    opacity: 1;
  }

  :nth-child(even) {
    background-color: #ccc;
    color: #000;
  }
`;

const GalleryBoxImage = styled.img`
  object-fit: cover;
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

class index extends Component {
  state = {
    selectedCategoryIndex: null,
    loading: false,
  };

  async componentDidMount() {
    this.setState({
      selectedCategoryIndex: null,
    });
  }

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width });
      img.onerror = reject;
      img.src = src;
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedCategoryIndex: null,
    });
  }
  updateProjectViewIndex = index => {
    this.props.setProjectIndex(index);
  };

  async getImageDimensions(imageUrl) {
    const result = await this.addImageProcess(imageUrl);
    return result;
  }

  render() {
    const Projects = ProjectList.map((project, index) => {
      if (index === 0) {
        return null;
      }
      return (
        <ProjectThumbnailContainer key={project.index} onClick={() => this.updateProjectViewIndex(index)}>
          <ProjectLabel>{project.title}</ProjectLabel>
          <Link to={project.path}>
            <Box>
              <GalleryBoxImage src={project.previewImage} height={250} width={250} />
              <Middle>
                <TitleText>{project.toolTipText}</TitleText>
              </Middle>
            </Box>
          </Link>
        </ProjectThumbnailContainer>
      );
    });

    return (
      <StyledFadeAnimation>
        <DarkmodeReduxSwitch />
        <Header>
          <h3>
            <FormattedMessage {...messages.allProjects} />
          </h3>

          <h6 style={{margin: '0px 15%'}}>In my current role over the past several years, I have dedicated my efforts to developing large-scale enterprise applications, implementing microservice architectures, and delivering cloud-based solutions. The following are examples of some fun, simpler projects I have worked on in the past. </h6>
        </Header>
        <GalleryCategoriesWrapper>
          <Wrapper>{Projects}</Wrapper>
        </GalleryCategoriesWrapper>
        <BottomPadding />
      </StyledFadeAnimation>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setProjectIndex: index => dispatch(setProjectViewIndex(index)),
});

const mapStateToProps = state => ({
  selectProjectIndex: selectProjectViewIndex(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
