import React from 'react';
import reactCSS from 'reactcss';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import { updateDarkmodeTheme, updateLightmodeTheme } from '../Settings/actions';
import { selectDarkmode } from '../../common/Theme/selectors';

class index extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    const { isDarkmode, updateDarkmode, updateLightmode } = { ...this.props };
    this.setState({ color: color.rgb });
    const newColor = { [this.props.variable]: color.hex };
    if (isDarkmode) updateDarkmode(newColor);
    else updateLightmode(newColor);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '40px',
          height: '18px',
          borderRadius: '1px',
          background: this.props.background,
        },
        swatch: {
          padding: '2px',
          background: this.props.color,
          borderRadius: '1px',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker color={this.state.color} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  // setDarkmodeBgColor: (color) => dispatch(setDarkmodeBackgroundColor(color)),
  updateDarkmode: color => dispatch(updateDarkmodeTheme(color)),
  updateLightmode: color => dispatch(updateLightmodeTheme(color)),
});

const mapStateToProps = state => ({
  isDarkmode: selectDarkmode(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
