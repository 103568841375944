import { defineMessages } from 'react-intl';

export default defineMessages({
  background: {
    id: 'Settings.background',
    defaultMessage: 'Background',
  },
  backgroundLighter: {
    id: 'Settings.backgroundLighter',
    defaultMessage: 'Background Lighter',
  },
  text: {
    id: 'Settings.text',
    defaultMessage: 'Text',
  },
  button: {
    id: 'Settings.button',
    defaultMessage: 'Button',
  },
  buttonHover: {
    id: 'Settings.buttonHover',
    defaultMessage: 'Button Hover',
  },
  loadingElement: {
    id: 'Settings.loadingElement',
    defaultMessage: 'Loading Element',
  },
  shadow: {
    id: 'Settings.shadow',
    defaultMessage: 'Shadow',
  },
  icon: {
    id: 'Settings.icon',
    defaultMessage: 'Icon',
  },
  iconHover: {
    id: 'Settings.iconHover',
    defaultMessage: 'Icon Hover',
  },
  resetToDefaults: {
    id: 'Settings.resetToDefaults',
    defaultMessage: 'Reset to Defaults',
  },
  anyChangesHere: {
    id: 'Settings.anyChangesHere',
    defaultMessage: 'Any changes made here will be reflected across the entire website.',
  },
  colorsLabel: {
    id: 'Settings.colorsLabel',
    defaultMessage: 'Colors:',
  },
  examplesLabel: {
    id: 'Settings.examplesLabel',
    defaultMessage: 'Examples:',
  },
  buttonsLabel: {
    id: 'Settings.buttonsLabel',
    defaultMessage: 'Buttons',
  },
  primaryButtonText: {
    id: 'Settings.primaryButtonText',
    defaultMessage: 'Primary Button',
  },
  secondaryButtonText: {
    id: 'Settings.secondaryButtonText',
    defaultMessage: 'Secondary Button',
  },
  textLabel: {
    id: 'Settings.textLabel',
    defaultMessage: 'Text',
  },
  loremIpsum: {
    id: 'Settings.loremIpsum',
    defaultMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  iconsLabel: {
    id: 'Settings.iconsLabel',
    defaultMessage: 'Icons',
  },
  saveAlertMessage: {
    id: 'Settings.saveAlertMessage',
    defaultMessage: 'Colors reset to default',
  },
});
