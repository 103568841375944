const lightBackground = 'white';
const darkBackground = '#20252b';
const lightBlue = '#6699cc';
const darkBlue = '#3c648c';

export const normalTheme = {
  color: 'black',
  color_darker: 'black',
  bg: lightBackground,
  bg_lighter: lightBackground,
  shadow_color: 'rgba(0, 0, 0, 0.69)',
  box_shadow: '5px 5px 10px 0px',
  button_text_color: lightBlue,
  active_button_text_color: 'white',
  button_bg_color: lightBackground,
  active_button_bg_color: lightBlue,
  main_button_color: 'white',
  main_button_primary: lightBlue,
  main_button_primary_darker: darkBlue,
  main_button_secondary: 'white',
  icon_color: '#767676',
  icon_hover_color: '#6699cc',
  button_spinner_color: '#B18264',
  playerColor: 'gray',
};

export const darkmodeTheme = {
  color: 'white',
  color_darker: '#ccc',
  bg: darkBackground,
  bg_lighter: '#272c34',
  shadow_color: 'rgba(0, 0, 0, 0.69)',
  box_shadow: '5px 5px 10px 0px',
  button_text_color: 'white',
  active_button_text_color: 'white',
  button_bg_color: darkBackground,
  active_button_bg_color: lightBlue,
  main_button_color: 'white',
  main_button_primary: '#00a598',
  main_button_primary_darker: '#01665e',
  main_button_secondary: 'white',
  icon_color: '#767676',
  icon_hover_color: '#6699cc',
  button_spinner_color: '#B18264',
  playerColor: 'gray',
};
