import {
  SET_USER_ID, SET_PLAYERS,
} from '../components/BasicGame/constants';
const initialState = {
  userId: null,
  players: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_PLAYERS:
      return {
        ...state,
        players: action.payload,
      };
    // case SET_DARKMODE:
    //   return {
    //     ...state,
    //     darkmode: !state.darkmode,
    //   };
    // case SET_DARKMODE_THEME:
    //   return {
    //     ...state,
    //     darkmodeConfig: { ...state.darkmodeConfig, ...action.payload },
    //   };
    // case SET_LIGHTMODE_THEME:
    //   return {
    //     ...state,
    //     lightmodeConfig: { ...state.lightmodeConfig, ...action.payload },
    //   };
    // case SAVE_FIREBASE_SETTINGS:
    //   return {
    //     ...state,
    //     ...action.payload,
    //     isFirebaseDataLoading: false,
    //   };
    // case GET_FIREBASE_SETTINGS:
    //   return {
    //     ...state,
    //     ...action.payload,
    //     isFirebaseSettingsLoading: false,
    //   };
    // case LOADING_FIREBASE_SETTINGS:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // case RESET_THEME_SETTINGS:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    default:
      return { ...state };
  }
};
