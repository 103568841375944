import { defineMessages } from 'react-intl';

export default defineMessages({
  zip: {
    id: 'WeatherProject.zip',
    defaultMessage: 'Enter a zip code:',
  },
  fiveDayForecast: {
    id: 'WeatherProject.fiveDayForecast',
    defaultMessage: ' Five Day Forecast',
  },
  dependingOnTimeOfDay: {
    id: 'WeatherProject.dependingOnTimeOfDay',
    defaultMessage: '*Depending on the time of day, only 4 days will be shown instead of 5. This is due to restrictions of the free version of the OpenWeatherAPI.',
  },
});
