import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setGalleryIndex } from '../GalleriesHome/actions';
import RunningStickMan from '../RunningStickMan';
import backdrop from '../../resources/background.jpg';
import profile from '../../resources/profile.jpg';
import { SlideInRightAnimation } from '../../common/Styles';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons';
import { setLanguage } from '../Settings/actions';

const Text = styled.div`
  color: red;
  font-size: 30px;
  text-decoration: none;
  text-underline-position: unset;
  color: white;
  padding: 16px 32px;
  font-family: 'Crimson Text', Arial, Helvetica, sans-serif;
  transition: all 0.5s;
  overflow: auto;

  * {
    color: ${props => props.theme.color};
  }
`;

const Middle = styled.div`
  transition: 0.5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  transition: all 0.5s;
  overflow: auto;
  @media (max-width: 900px) {
    top: 30%;
  }
`;

const Box = styled.div`
  position: relative;
  align-content: center;
  justify-content: center;
  background: ${props => `url(${backdrop}) no-repeat bottom center`};
  width: 100%;
  color: #ccc;
  height: 100vh;
  transition: all 0.5s;
  overflow: auto;
`;

const Profile = styled.img`
  border-radius: 50%;
  height: 40%;
  width: 40%;
  transition: all 0.5s;
  overflow: auto;
`;

const IndicatorContainer = styled.div`
  display: flex;
`;

const PulsingArrow = styled.div`
  margin-top: -90px;
  margin-left: 10px;
  animation: blinker 0.6s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

  @keyframes blinker {
    to {
      opacity: 0.6;
      transform: translate3d(-7px, 0, 0);
    }
  }

  @media (max-width: 1000px) {
    padding-top: 508px;
  }
`;
const LanguagePicker = styled.div`
  position: absolute;
  height: 30px;
  top: 0;
  right: 0;
  left: 1;
  bottom: 0;
  z-index: 999;
`;
const Language = styled.span`
  margin: 3px;
  &:hover {
    cursor: pointer;
    color: gray;
    color: ${props => props.theme.icon_hover_color};
    cursor: pointer;
    transform: scale(1.1);
  }
  transition: 250ms ease all;
`;

class index extends Component {
  render() {
    return (
      <SlideInRightAnimation>
        {/* <LanguagePicker>
          <Language onClick={() => this.props.setLanguage('en')}>EN</Language> | <Language onClick={() => this.props.setLanguage('zn')}>中文</Language>
        </LanguagePicker> */}
        <Box>
          {/* <StyledInfo>
            This page is currently under development. You can view my old
            portfolio here:{' '}
            <a href="https://chrisanderson27.github.io/chrisanderson/">
              https://chrisanderson27.github.io/chrisanderson/
            </a>
          </StyledInfo> */}
          {/* <PulsingArrow>
            <IndicatorContainer>
              <FontAwesomeIcon icon={faHandPointLeft} size="2x"></FontAwesomeIcon>
              <div style={{ alignSelf: 'center', marginLeft: '8px' }}>
                <h6 sx={{ marginBottom: '0px !important' }}>
                  <FormattedMessage {...messages.customize} sx={{ marginBottom: '0px !important' }} />
                </h6>
              </div>
            </IndicatorContainer>
          </PulsingArrow> */}
          <Middle>
            <Text>
              <h1>
                <FormattedMessage {...messages.name} />
              </h1>
              <h4>
                <FormattedMessage {...messages.jobTitle} />
              </h4>
            </Text>
            <Profile onLoad={this.profileLoaded} src={profile} />
          </Middle>
          <RunningStickMan />
        </Box>
      </SlideInRightAnimation>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGalleryIndex: index => dispatch(setGalleryIndex(index)),
  setLanguage: language => dispatch(setLanguage(language)),
});

export default connect(
  null,
  mapDispatchToProps
)(index);
