import { defineMessages } from 'react-intl';

export default defineMessages({
  website: {
    id: 'CokeamizerPhotographyPage.website',
    defaultMessage: 'cokeamizer.netlify.com',
  },
  title: {
    id: 'CokeamizerPhotographyPage.title',
    defaultMessage: 'Share a Coke with BitMoji integration',
  },
  objective: {
    id: 'CokeamizerPhotographyPage.objective',
    defaultMessage: 'Objective:',
  },
  userStoryLabel: {
    id: 'CokeamizerPhotographyPage.userStoryLabel',
    defaultMessage: 'User Story:',
  },
  finalProductLabel: {
    id: 'CokeamizerPhotographyPage.finalProductLabel',
    defaultMessage: 'Final Product:',
  },
  viewRecorded: {
    id: 'CokeamizerPhotographyPage.viewRecorded',
    defaultMessage: 'View a Recorded Demo',
  },
  viewLive: {
    id: 'CokeamizerPhotographyPage.viewLive',
    defaultMessage: 'View the Live Site',
  },
  techAndArch: {
    id: 'CokeamizerPhotographyPage.techAndArch',
    defaultMessage: 'Architecture Diagram',
  },
  userStory: {
    id: 'CokeamizerPhotographyPage.userStory',
    defaultMessage:
      "As a Coke consumer, I want to send my friend a customized bottle of Coke with our Bitmoji's on the label. Within the web application, I design my label, add my friends name, and their Bitmoji. I then fill out the delivery and payment information. On form submission, the Coke bottle label is saved as an image and stored on a server, waiting to be printed. My friend receives an email containing a QR code and scans it at a local special Coke machine. The machine fetches, redeems, and prints the new label onto a fresh bottle of Coke. The api work, emails, and backend QR code validation is all functional for this application.",
  },
  descriptionOne: {
    id: 'CokeamizerPhotographyPage.descriptionOne',
    defaultMessage:
      'This application is the result of a 2 day hackathon that took place with partners Tinroof Software and Coka-Cola. Coca-Cola wants to use technology to make their “Share a Coke” marketing campaign come alive. The goal of the hackathon was for a team to implement a use case of someone sharing a coke with a friend.',
  },
  descriptionTwo: {
    id: 'CokeamizerPhotographyPage.descriptionTwo',
    defaultMessage: 'This application was built with React.js, and a Java SpringBoot AWS Server for customer data validation, QR code generation, and database storage',
  },
});
