import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
`;

const IconWrapper = styled.div`
  margin: 30px 0px;
`;
const StyledHighText = styled.div`
  margin-top: 15px;
  font-weight: 800;
  font-size: 25px;
`;
const StyledLowText = styled.div`
  margin-top: 5px;
  font-weight: 800;
  font-size: 16px;
`;

const index = ({ data, city }) => {
  return (
    <Container>
      <h4>{data.date}</h4>
      <IconWrapper>
        <FontAwesomeIcon icon={data.icon} size="4x"></FontAwesomeIcon>
      </IconWrapper>
      <b>{data.description}</b>
      <StyledHighText>{Math.ceil(data.high)}°F</StyledHighText>
      <StyledLowText>{Math.floor(data.low)}°F</StyledLowText>
    </Container>
  );
};

export default index;
