export function selectIsFirebaseDataLoading(state) {
  return state.ThemeReducer.isFirebaseDataLoading;
}
export function selectLanguage(state) {
  return state.ThemeReducer.language;
}
export function selectVariableColor(state) {
  if (state.ThemeReducer.darkmode) {
    return state.ThemeReducer.darkmodeConfig;
  } else {
    return state.ThemeReducer.lightmodeConfig;
  }
}
