/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  faEnvelope,
  faFileAlt,
  faHome,
  faAddressCard,
  faCode,
  faArrowLeft,
  faCogs
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";
import { ProjectList } from "../ProjectsPage/constants";
import { slideInLeft } from "react-animations";
import { setProjectViewIndex } from "../ProjectsPage/actions";
import { selectProjectViewIndex } from "../ProjectsPage/selectors";
import ThemeChangeGif from "../../resources/themeChange.gif";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const animationInLeft = keyframes`${slideInLeft}`;
const MobileBreakpoint = "1000px";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: #323843;
  top: 0%;
  left: 0%;

  a {
    text-decoration: none;
  }

  @media (max-width: ${MobileBreakpoint}) {
    overflow-y: auto;
  }
`;

const IconLabel = styled.span`
  display: none;

  @media (max-width: ${MobileBreakpoint}) {
    display: inherit;
  }
`;
const ThemeImg = styled.img`
  width: 300px;
`;
const FixedToBottom = styled.div`
  position: relative;
  bottom: 0;
  margin-bottom: 15px;
`;

const ToolTipText = styled.div`
  visibility: hidden;
  min-width: 120px;
  background-color: #20252b;
  color: #dcdcdc;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: 110%;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #767676 transparent transparent;
  }
  @media (max-width: ${MobileBreakpoint}) {
    display: none;
  }
`;

const StyledIcon = styled.div`
  position: relative;
  width: 60px;
  align-items: center;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  transition: color 0.5s;
  color: ${props => (props.isSelected ? "#6699cc" : "#767676")};
  color: ${props => props.theme.icon_color};
  transform: ${props => (props.isSelected ? "scale(1.1);" : "")};
  text-decoration: none;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 500;
  animation: 0.6s ${animationInLeft};

  &:hover {
    color: ${props => props.theme.icon_hover_color};
    cursor: pointer;
    transform: scale(1.1);
  }

  &:hover ${ToolTipText} {
    visibility: visible;

    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 400ms;

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

const StableIcon = styled(FontAwesomeIcon)`
color: ${props => (props.isSelected ? props.theme.icon_hover_color : props.theme.icon_color)};
  &:hover {
    color: ${props => props.theme.icon_hover_color};
    cursor: pointer;
    transform: scale(1.1);
  }
`;

class index extends Component {
  state = {
    showProjectView: false,
    currentProjectIndex: null
  };

  toggleProjectView = () => {
    const showProjectView = this.state.showProjectView;
    this.setState({ showProjectView: !showProjectView });
  };

  toggleHomeView = () => {
    this.setState({ showProjectView: false });
  };
  toggleActiveStatus = currentProjectIndex => {
    this.props.setProjectIndex(currentProjectIndex);
  };

  homeIcon = (
    <Link to="/">
      <StyledIcon>
        <IconLabel>
          <FormattedMessage {...messages.homeLabel} />
        </IconLabel>
        <FontAwesomeIcon icon={faHome} size="2x" />
        <ToolTipText>
          <FormattedMessage {...messages.homeDesc} />
        </ToolTipText>
      </StyledIcon>
    </Link>
  );



  render() {
    {
      const projectIcons = ProjectList.map((element, index) => (
        <Link
          to={element.path}
          key={element.title}
          onClick={() => this.toggleActiveStatus(index)}
        >
          <StyledIcon
            {...this.state}
            index={index}
            isSelected={this.props.selectProjectIndex === index}
          >

            <StableIcon icon={element.icon} size="2x"             isSelected={this.props.selectProjectIndex === index}
 />
            <ToolTipText>{element.toolTipText}</ToolTipText>
          </StyledIcon>
        </Link>
      ));
      if (!this.state.showProjectView) {
        return (
          <Container>
            {this.homeIcon}

            <Link to="/about">
              <StyledIcon>
                <IconLabel>
                  <FormattedMessage {...messages.aboutLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faAddressCard} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.aboutDesc} />
                </ToolTipText>
              </StyledIcon>
            </Link>
            {/* <a
              href="https://docs.google.com/document/d/1tEP03fjCE1kB3O5rfMTI6SozmiZ69lEEyVpWYn-9vF0/edit"
              target="_blank"
            >
              <StyledIcon>
                <IconLabel>
                  <FormattedMessage {...messages.resumeLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faFileAlt} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.resumeDesc} />
                </ToolTipText>
              </StyledIcon>
            </a> */}
            <Link to="/projects" onClick={this.toggleProjectView}>
              <StyledIcon>
                <IconLabel>
                  <FormattedMessage {...messages.projectsLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faCode} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.projectsDesc} />
                </ToolTipText>
              </StyledIcon>
            </Link>

            <a
              href="mailto:chrisanderson@myself.com?Subject=I%20came%20across%20your%20porftolio..."
              target="_blank"
            >
              <StyledIcon>
                <IconLabel>
                  <FormattedMessage {...messages.emailLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.emailDesc} />
                </ToolTipText>
              </StyledIcon>
            </a>
            <a href="https://www.linkedin.com/in/chrisaaxx/" target="_blank">
              <StyledIcon>
                <IconLabel>
                  <FormattedMessage {...messages.linkedinLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.linkedinDesc} />
                </ToolTipText>
              </StyledIcon>
            </a>
            {/* <a href="https://github.com/chrisanderson27" target="_blank">
              <StyledIcon>
                <IconLabel>
                  <FormattedMessage {...messages.githubLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faGithub} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.githubDesc} />
                </ToolTipText>
              </StyledIcon>
            </a> */}
            <FixedToBottom>
              <Link to="/settings">
                <StyledIcon>
                  <IconLabel>
                    <FormattedMessage {...messages.settingsLabel} />
                  </IconLabel>
                  <FontAwesomeIcon icon={faCogs} size="2x" />
                  <ToolTipText>
                    <FormattedMessage {...messages.settingsDesc} />
                    <ThemeImg src={ThemeChangeGif} />
                  </ToolTipText>
                </StyledIcon>
              </Link>
            </FixedToBottom>
          </Container>
        );
      } else {
        return (
          <Container>
            <Link to="/">
              <StyledIcon onClick={this.toggleProjectView}>
                <IconLabel>
                  <FormattedMessage {...messages.returnLabel} />
                </IconLabel>
                <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                <ToolTipText>
                  <FormattedMessage {...messages.returnDesc} />
                </ToolTipText>
              </StyledIcon>
            </Link>
            {projectIcons}
          </Container>
        );
      }
    }
  }
}

const mapDispatchToProps = dispatch => ({
  setProjectIndex: index => dispatch(setProjectViewIndex(index))
});

const mapStateToProps = state => ({
  selectProjectIndex: selectProjectViewIndex(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
