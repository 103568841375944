import React from 'react';
import styled from 'styled-components';
import cokeGif from '../../resources/projectAssets/Coke/coke.gif';
import cokeTinroof from '../../resources/projectAssets/Coke/cokeTinroof.png';
import cokeArch from '../../resources/projectAssets/Coke/cokeArch.png';
import { FadeAnimationNoStyles } from '../../common/Styles';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import Button from '../../common/Button';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 0px 30px;
  margin: 0px auto;
  background-color: ${({ theme }) => theme.bg};
  text-align: center;

  box-shadow: ${({ theme }) => theme.box_shadow + ' ' + theme.shadow_color};
  color: ${({ theme }) => theme.color};
`;

const Description = styled.div`
  margin-bottom: 50px;
  width: 60%;
  text-align: center;

  @media (max-width: 700px) {
    width: 95%;
  }
`;
const DescriptionOutline = styled.div`
  margin-bottom: 50px;
  justify-content: center;
  display: flex;
  text-align: center;

  @media (max-width: 700px) {
  }
`;

const Title = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 26px;
  word-break: break-all;

  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
  @media (max-width: 300px) {
    font-size: 12px;
  }
`;

const GifWrapper = styled.div`
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  padding: 2px;
`;

const StyledButton = styled(Button)`
  margin: 10px;
`;

const Gif = styled.img`
  max-width: 800px;
  width: 100%;
  margin-bottom: 30px;
`;
const CokeTinroof = styled.img`
  max-width: 700px;
  width: 100%;
  margin-bottom: 30px;
`;
const CokeArch = styled.img`
  max-width: 800px;
  width: 100%;
  margin-bottom: 80px;
`;

const index = props => {
  return (
    <FadeAnimationNoStyles>
      <DarkmodeReduxSwitch />
      <Container>
        <CokeTinroof src={cokeTinroof} />
        <Description>
          <h2>
            <FormattedMessage {...messages.objective} />
          </h2>
          <FormattedMessage {...messages.descriptionOne} />
        </Description>
        <h2>
          <FormattedMessage {...messages.userStoryLabel} />
        </h2>
        <DescriptionOutline>
          <FormattedMessage {...messages.userStory} />
        </DescriptionOutline>
        <h2>
          <FormattedMessage {...messages.finalProductLabel} />
        </h2>
        {/* <ButtonWrapper>
          <a href="https://drive.google.com/file/d/11S_x_zWLXKP3QZAQDsvlROqCyWPzuLI_/view?usp=sharing" target="_blank">
            <StyledButton primary>
              <FormattedMessage {...messages.viewRecorded} />
            </StyledButton>
          </a>
          <a href="https://cokeamizer.netlify.com" target="_blank">
            <StyledButton primary>
              <FormattedMessage {...messages.viewLive} />
            </StyledButton>
          </a>
        </ButtonWrapper> */}
        <a href="https://cokeamizer.netlify.com" target="_blank">
          <GifWrapper>
            <Gif src={cokeGif} />
          </GifWrapper>
        </a>
        <h3 style={{marginBottom: '40px'}}>
          <FormattedMessage {...messages.techAndArch} />
        </h3>
        <CokeArch src={cokeArch} />
      </Container>
    </FadeAnimationNoStyles>
  );
};

export default index;
