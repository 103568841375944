import React, { Component } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  height: 600px;
  max-height: 600px;
  width: 100%;
  padding: 20px 150px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: ${({ darkmode }) => (darkmode ? '#20252b' : '#ebf7f9')}; */
  background-color: ${({ theme }) => theme.bg};


  .recharts-tooltip-wrapper {
    color: ${({ darkmode }) => (darkmode ? 'black' : '')};
  }

  @media (max-width: 1200px) {
    width: 100%;
    padding: 50px;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
    height: 450px;
  }
  @media (max-width: 580px) {
    width: 100%;
    padding: 0px;
    height: 300px;
  }
`;
class index extends Component {
  render() {
    const { data, city } = { ...this.props.forecastData };
    let parsedData = data.map(obj => {
      const date = obj.date.substring(0, 4);
      return { ...obj, date };
    });
    const highValues = parsedData.map(obj => {
      return obj.high;
    });
    const lowValues = parsedData.map(obj => {
      return obj.low;
    });
    const min = Math.min(...lowValues);
    const max = Math.max(...highValues);

    const domain = [Math.floor(min) - 10, Math.ceil(max) + 5];
    return (
      <Container darkmode={this.props.darkmode}>
        <h4>
          {city + " "}
        {this.props.intl.formatMessage(messages.highsAndLows,{
          city,
        }) }
          {/* <FormattedMessage {...messages.highsAndLows} /> */}
        </h4>
        <ResponsiveContainer width="90%" height="90%">
          <AreaChart allowDecimals={false} width={50} height={500} data={parsedData}>
            <Legend
              width={100}
              wrapperStyle={{
                top: 0,
                right: 0,
                borderRadius: 3,
                lineHeight: '40px',
              }}
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#F08080" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#F08080" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="date"></XAxis>
            <YAxis type="number" domain={domain} allowDecimals={false} minTickGap={1}></YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="high" stroke="#F08080" fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="low" stroke="#8884d8" fillOpacity={1} fill="url(#colorPv)" />
          </AreaChart>
        </ResponsiveContainer>
      </Container>
    );
  }
}

export default injectIntl(index);
