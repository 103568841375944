import AppointmentsReducer from '../components/AppointmentsProject/reducer';
import ProjectReducer from './projectReducer';
import GalleryReducer from './galleryReducer';
import ThemeReducer from './themeReducer';
import GameReducer from './gameReducer';

/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
export default combineReducers({
  GalleryReducer,
  AppointmentsReducer,
  ProjectReducer,
  ThemeReducer,
  GameReducer
});
