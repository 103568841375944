import {
  SET_USER_ID,
  SET_PLAYERS,
} from "./constants";
// import { getSettings, saveSettings } from "../../utils/firebase_utils";
// import { normalTheme, darkmodeTheme } from "../../constants/theme";

export const setUserId = userId => dispatch => {
  dispatch({
    type: SET_USER_ID,
    payload: userId
  });
};
export const setPlayers = players => dispatch => {
  dispatch({
    type: SET_PLAYERS,
    payload: players
  });
};
// export const setDarkmodeBackgroundColor = color => dispatch => {
//   dispatch({
//     type: SET_DARKMODE_BG,
//     payload: color
//   });
// };
// export const updateDarkmodeTheme = variable => dispatch => {
//   dispatch({
//     type: SET_DARKMODE_THEME,
//     payload: variable
//   });
// };
// export const updateLightmodeTheme = variable => dispatch => {
//   dispatch({
//     type: SET_LIGHTMODE_THEME,
//     payload: variable
//   });
// };
// export const saveFirebaseSettings = userId => async (dispatch, getState) => {
//   const data = { ...getState().ThemeReducer };
//   dispatch({
//     type: LOADING_FIREBASE_SETTINGS,
//     payload: { isFirebaseDataLoading: true }
//   });
//   await saveSettings(userId, data);
//   dispatch({
//     type: SAVE_FIREBASE_SETTINGS,
//     payload: data
//   });
// };
// export const resetThemeSettings = () => dispatch => {
//   dispatch({
//     type: RESET_THEME_SETTINGS,
//     payload: {
//       darkmodeConfig: { ...darkmodeTheme },
//       lightmodeConfig: { ...normalTheme }
//     }
//   });
// };

// export const getFirebaseSettings = userId => async dispatch => {
//   dispatch({
//     type: LOADING_FIREBASE_SETTINGS,
//     payload: { isFirebaseDataLoading: true }
//   });
//   const data = await getSettings(userId).then(res => res);
//   dispatch({
//     type: GET_FIREBASE_SETTINGS,
//     payload: data
//   });
//   dispatch({
//     type: LOADING_FIREBASE_SETTINGS,
//     payload: { isFirebaseDataLoading: false }
//   });
// };
