import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../common/Button';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const BodyTitle = styled.h4``;
const Title = styled.h4`
  text-align: center;
  margin: 0px auto;
`;

const Container = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
`;

class model extends Component {
  render() {
    return (
      <Modal {...this.props} size="lg" centered>
        <Container>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Title>
                {this.props.playerData && this.props.playerData.id}
              </Title>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BodyTitle>
            </BodyTitle>
            <p>
              <label>
              </label>
              <br />
              <br />
              <label>
              </label>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button primary onClick={this.props.onHide}>
              <FormattedMessage {...messages.close} />
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    );
  }
}

export default model;