import { SET_DARKMODE } from './constants';
import { darkmodeTheme, normalTheme } from '../constants/theme';
import {
  SET_DARKMODE_THEME,
  SET_LIGHTMODE_THEME,
  SAVE_FIREBASE_SETTINGS,
  GET_FIREBASE_SETTINGS,
  RESET_THEME_SETTINGS,
  LOADING_FIREBASE_SETTINGS,
  SET_LANGUAGE,
} from '../components/Settings/constants';
const initialState = {
  darkmode: true,
  darkmodeConfig: { ...darkmodeTheme },
  lightmodeConfig: { ...normalTheme },
  isFirebaseDataLoading: false,
  language: 'en',
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_DARKMODE:
      return {
        ...state,
        darkmode: !state.darkmode,
      };
    case SET_DARKMODE_THEME:
      return {
        ...state,
        darkmodeConfig: { ...state.darkmodeConfig, ...action.payload },
      };
    case SET_LIGHTMODE_THEME:
      return {
        ...state,
        lightmodeConfig: { ...state.lightmodeConfig, ...action.payload },
      };
    case SAVE_FIREBASE_SETTINGS:
      return {
        ...state,
        ...action.payload,
        isFirebaseDataLoading: false,
      };
    case GET_FIREBASE_SETTINGS:
      return {
        ...state,
        ...action.payload,
        isFirebaseSettingsLoading: false,
      };
    case LOADING_FIREBASE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_THEME_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
