import React, { Component } from 'react';
import styled from 'styled-components';
import { FadeAnimationNoStyles } from '../../common/Styles';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import Button from '../../common/Button';
import DeeplinkModal from './DeeplinkModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 0px 30px;
  margin: 0px auto;
  background-color: ${({ theme }) => theme.bg};
  text-align: center;

  box-shadow: ${({ theme }) => theme.box_shadow + ' ' + theme.shadow_color};
  color: ${({ theme }) => theme.color};



  opacity: 0.3;
`;


const compAppSetting = 'https://apps.apple.com/us/app/coupa-cafe-app/id1199495613';
const companyAppId = compAppSetting.substring(compAppSetting.lastIndexOf('/') + 3);
const itunesLookupBase = 'http://itunes.apple.com/lookup?id='
const lookupUrl = itunesLookupBase + companyAppId;
class index extends Component {

  state = {
    isLoaded: false,
    appleIcon: null,
  };

  componentDidMount() {
    // console.log(lookupUrl)
    // fetch(lookupUrl)
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result)
    //       this.setState({
    //         isLoaded: true,
    //         appleIcon: result.results[0].artworkUrl60
    //       });
    //     },
    //     // Note: it's important to handle errors here
    //     // instead of a catch() block so that we don't swallow
    //     // exceptions from actual bugs in components.
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         appleIcon: null
    //       });
    //     }
    //   )
  }

  openApp = () => {
    console.log('openApp...')
  }

  render() {

    if (!this.state.isLoaded) {
      return <div>Loading...</div>
    }

    return (
      <FadeAnimationNoStyles>
        <DarkmodeReduxSwitch />
        <DeeplinkModal deeplinkToApp={'http://www.coupacafe.com/dleng/sites'} />
        <Container>
          <br />
          <br />
          <br />
          <Button>Continue With Browser</Button>
          <br />
          <br />
          <br />
          <Button onClick={() => this.openApp}>Open App</Button>
          <br />
          <br />
          <br />
          <a href='http://www.coupacafe.com/dleng/sites'>http://www.coupacafe.com/dleng/sites</a>
          <br />
          <br />
          <br />
          <a href='https://apps.apple.com/us/app/coupa-cafe-app/id1199495613'>
            https://apps.apple.com/us/app/coupa-cafe-app/id1199495613
        </a>
          <div>
            <img src={this.state.appleIcon} alt="alt" />
          </div>
        </Container>
      </FadeAnimationNoStyles >
    );
  }
};

export default index;