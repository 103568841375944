import styled, { keyframes } from 'styled-components';
import { slideInRight } from 'react-animations';

const animation = keyframes`${slideInRight}`;

export const FadeAnimation = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 700ms;
  background-color: ${({ theme }) => theme.bg};

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const FadeAnimationNoStyles = styled.div`
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: ${(props) => props.time || '700ms'};
  background-color: ${({ theme }) => theme.bg};

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SlideInRightAnimation = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  animation: 1s ${animation};
  background-color: ${({ theme }) => theme.bg};
`;
export const SlideInRightAnimationNoStyles = styled.div`
  animation: 1s ${animation};
  background-color: ${({ theme }) => theme.bg};
`;
