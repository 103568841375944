import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';
import { updateLocation } from '../../utils/game_utils';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import Modal from './modal';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { FadeAnimationNoStyles } from '../../common/Styles';
import ColorPicker from '../ColorPicker/indexGeneric';
import { selectVariableColor } from '../Settings/selectors';
import { updateDarkmodeTheme, updateLightmodeTheme } from '../Settings/actions';
import { selectDarkmode } from '../../common/Theme/selectors';
import { selectUserId, selectPlayers } from './selectors';
import { setUserId, setPlayers } from './actions';
import BasicGameGif from "../../resources/projectAssets/BasicGame/BasicGame.gif";
import { FormattedMessage } from 'react-intl';
import messages from './messages';


const Description = styled.div`
  margin: 0px auto;
  width: 60%;
  text-align: center;

  @media (max-width: 700px) {
    width: 90%;
  }
`;

const Title = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 26px;
  word-break: break-all;

  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
  @media (max-width: 300px) {
    font-size: 12px;
  }
`;

const GridContainer = styled.div`
margin: 0px auto;
width: fit-content;
background-color: white;
display: flex;
flex-wrap: wrap;
box-shadow: ${({ theme }) => theme.box_shadow + ' ' + theme.shadow_color};
`;

const Grid = styled.div`
height: 20px;
width: 20px;
background-color: ${({ isOccupied, color }) => isOccupied ? color : 'gray'};
margin: 1px;
`;

const GridColumn = styled.div`
display: flex;
flex-direction: column-reverse;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.color};
  margin-right: 10px;
`;

const Setting = styled.div`
  height: 30px;
  max-width: 320px;
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around
  text-align: right;


  @media (max-width: 500px) {
    text-align: center;
  }
`;

const SpinnerWrapper = styled.div`
margin: 0px auto;
width: fit-content;
padding: 20%;
`;

const Header = styled.h6`
text-decoration: underline;
`;

const FadeAnimation = styled(FadeAnimationNoStyles)`
display: flex;
flex-direction: column;
`;

const COLLECTION_NAME = 'game';
// MINUTES * 60 * 1000;
const PLAYER_TIME_TO_LIVE = 5 * 60 * 1000;
const PLAYER_COUNT = 10;

class index extends Component {

  state = {
    gridWidth: 15,
    players: [],
    content: '',
    userId: null,
    isLoading: true,
    showPlayerModal: false,
  };

  async componentDidMount() {
    const { updatePlayers } = { ...this.props };
    document.addEventListener("keydown", this.checkArrowDirection);
    const db = firebase.firestore();
    const scope = this;
    //WATCH ALL PLAYERS
    await Array.from({ length: PLAYER_COUNT }, async (_, i) => {
      await db.collection(COLLECTION_NAME)
        .doc(i + '')
        .onSnapshot(async function (doc) {
          const data = doc.data();
          //IF PLAYER DOES NOT EXIST, CREATE THEM
          if (data === undefined) {
            //TODO: make these random
            const initialData = {
              xPosition: 0,
              yPosition: 0,
              color: 'black',
              id: i + '',
              lastModifiedTimestamp: new Date().toUTCString()
            }
            await updateLocation(i + '', initialData);
            // scope.setState(prevState => ({ players: [...prevState.players, ...initialData] }));
          }
          // scope.updateThemePlayerColorOnLoad(data.color);
          let players = [...scope.state.players];
          let player = { ...players[i] };
          player = { ...data }
          players[i] = player;
          scope.setState({ players });
          updatePlayers(players);
          scope.setGridContent();
        });
    });

    //Get players and then render them on the board
    const currentTime = new Date();
    await Array.from({ length: PLAYER_COUNT }, async (_, i) => {
      db
        .collection(COLLECTION_NAME).doc(`${i}`)
        .get().then(() => {
          if (i === PLAYER_COUNT - 1) {
            this.setGridContent();
            scope.setState({ isLoading: false });
            //On page load, check all players idle times. If not idle, remove occupied status.
            scope.state.players.forEach(player => {
              this.checkPlayerTimestamp(currentTime, player);
            });
          }
        });
    });

  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.checkArrowDirection, false);
  }

  checkPlayerTimestamp = (currentTime, player) => {
    const lastModifiedTimestamp = player.lastModifiedTimestamp;
    const elaspedTime = currentTime - new Date(lastModifiedTimestamp);

    if (elaspedTime > PLAYER_TIME_TO_LIVE)
      this.removeSelectedUserId(player.id)
  }

  checkArrowDirection = async (event) => {
    event.preventDefault();
    const userId = this.state.userId;
    const { updatePlayers } = { ...this.props };
    if (userId === null) {
      return;
    }

    let players = [...this.state.players];
    let player = { ...players[userId] };
    let newPosition = {
      ...player,
      lastModifiedTimestamp: new Date().toUTCString()
    };

    switch (event.which) {
      case 37:
        // alert('left');
        newPosition.xPosition = newPosition.xPosition > 0 ? newPosition.xPosition - 1 : 0
        break;
      case 38:
        // alert('up');
        newPosition.yPosition = newPosition.yPosition < this.state.gridWidth - 1 ? newPosition.yPosition + 1 : newPosition.yPosition;
        break;
      case 39:
        // alert('right');
        newPosition.xPosition = newPosition.xPosition < this.state.gridWidth - 1 ? newPosition.xPosition + 1 : newPosition.xPosition;
        break;
      case 40:
        // alert('down');
        newPosition.yPosition = newPosition.yPosition > 0 ? newPosition.yPosition - 1 : 0;
        break;
      default:
        return;
    }

    player = { ...player, ...newPosition, }
    players[userId] = player;
    this.setState({ players });
    updatePlayers(players);

    await updateLocation(userId, player)
  }

  updateThemePlayerColorOnLoad = color => {
    const { isDarkmode, updateDarkmode, updateLightmode } = { ...this.props };
    const newColor = { 'playerColor': color };
    if (isDarkmode) updateDarkmode(newColor);
    else updateLightmode(newColor);
  };

  setGridContent = () => {
    const {
      gridWidth
    } = { ...this.state };
    const { players } = { ...this.props }
    const content = Array.from({ length: gridWidth }, (_, i) =>

      <GridColumn id={`col${i}`} key={i}>
        {Array.from({ length: gridWidth }, (_, l) => {
          let isOccupied = false;
          let playerData = null;
          players.forEach(player => {
            if (player.xPosition === i && player.yPosition === l) {
              isOccupied = true;
              playerData = { ...player };
            }
          });

          return (
            <Grid id={`row${i}col${l}`} key={l} isOccupied={isOccupied} color={isOccupied ? playerData.color : 'gray'} />
          )
        })
        }
      </GridColumn>
    );
    this.setState({
      content
    });
  }

  handleColorChange = (color) => {
    const { updatePlayers } = { ...this.props };
    const userId = this.state.userId;
    let players = [...this.state.players];
    let player = { ...players[userId] };

    let newPosition = {
      ...player,
      color: color.hex
    };

    player = { ...player, ...newPosition, }
    players[userId] = player;
    // this.setState({ players });
    updatePlayers(players);
    updateLocation(userId, player)
  }

  handleSelectUser = (id) => {
    const previousUserId = this.state.userId;

    //remove previous selection...
    if (previousUserId !== null)
      this.removeSelectedUserId(previousUserId);

    //add new selection...
    this.addSelectedUserId(id + '');
  }

  removeSelectedUserId = (id) => {
    const { updatePlayers } = { ...this.props };
    let players = [...this.state.players];
    let player = { ...players[id] };
    let newPosition = {
      ...player,
      isSelected: false,
    };
    player = { ...player, ...newPosition, }
    players[id] = player;
    // this.setState({ players });
    updatePlayers(players);
    updateLocation(id, player)
  }

  addSelectedUserId = (id) => {
    const { updatePlayers } = { ...this.props };
    let players = [...this.state.players];
    let player = { ...players[id] };
    let newPosition = {
      ...player,
      isSelected: true,
    };
    player = { ...player, ...newPosition, }
    players[id] = player;
    updateLocation(id, player)
    this.setState({ players, userId: `${id}`, showPlayerModal: true });
    // ?
    updatePlayers(players);

    this.props.updateReduxUserId(id);
  }

  resetSelectedButtons = () => {
    Array.from({ length: PLAYER_COUNT }, async (_, i) => {
      this.removeSelectedUserId(`${i}`);
    });
  }


  render() {
    const {
      content, userId, isLoading, showPlayerModal
    } = { ...this.state };

    const { players } = { ...this.props }

    const settingsList = Array.from({ length: PLAYER_COUNT }, (_, i) =>
      <Setting>
        <Button occupied={players[i] && players[i].isSelected && userId !== i + ''} disabled={players[i] && players[i].isSelected && userId !== i + ''} primary={userId === i + ''} onClick={() => this.handleSelectUser(i)}>Select</Button>
        <Label>Player {i}:</Label>
        <ColorPicker disabled={userId !== i + ''} onChange={this.handleColorChange} variable={'playerColor'} background={players[i] && players[i].color} color={players[i] && players[i].color} />
      </Setting>
    )

    return (
      isLoading ?
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper> :
        (
          <FadeAnimation>
            <DarkmodeReduxSwitch />
            <Title>
              <FormattedMessage {...messages.title} />
            </Title>

            <Description>
              <Header>
              <img src={BasicGameGif}style={{margin: '10px'}} />
              </Header>
              <Header>
                <FormattedMessage {...messages.instructionsHeader} />
              </Header>
              <p>
                <FormattedMessage {...messages.instructions} />
              </p>
              <Header>
                <FormattedMessage {...messages.descriptionHeader} />
              </Header>

              <p>


                <FormattedMessage {...messages.description} />
              </p>
            </Description>
            <GridContainer>
              {content}
            </GridContainer>
            {settingsList}

          </FadeAnimation>
        )
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateDarkmode: color => dispatch(updateDarkmodeTheme(color)),
  updateLightmode: color => dispatch(updateLightmodeTheme(color)),
  updateReduxUserId: userId => dispatch(setUserId(userId)),
  updatePlayers: players => dispatch(setPlayers(players)),
});

const mapStateToProps = state => ({
  selectSettingColor: selectVariableColor(state),
  isDarkmode: selectDarkmode(state),
  reduxUserId: selectUserId(state),
  players: selectPlayers(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);