import { defineMessages } from 'react-intl';

export default defineMessages({
  homeLabel: {
    id: 'SideNavBar.homeLabel',
    defaultMessage: 'Home',
  },
  homeDesc: {
    id: 'SideNavBar.homeDesc',
    defaultMessage: 'Go Home',
  },
  aboutLabel: {
    id: 'SideNavBar.aboutLabel',
    defaultMessage: 'About',
  },
  aboutDesc: {
    id: 'SideNavBar.aboutDesc',
    defaultMessage: 'About Me',
  },
  resumeLabel: {
    id: 'SideNavBar.resumeLabel',
    defaultMessage: 'Resume',
  },
  resumeDesc: {
    id: 'SideNavBar.resumeDesc',
    defaultMessage: 'View my resume',
  },
  projectsLabel: {
    id: 'SideNavBar.projectsLabel',
    defaultMessage: 'Projects ',
  },
  projectsDesc: {
    id: 'SideNavBar.projectsDesc',
    defaultMessage: 'View my work',
  },
  emailLabel: {
    id: 'SideNavBar.emailLabel',
    defaultMessage: 'Email',
  },
  emailDesc: {
    id: 'SideNavBar.emailDesc',
    defaultMessage: 'Send me an email',
  },
  linkedinLabel: {
    id: 'SideNavBar.linkedinLabel',
    defaultMessage: 'Linkedin',
  },
  linkedinDesc: {
    id: 'SideNavBar.linkedinDesc',
    defaultMessage: 'View my Linkedin profile',
  },
  githubLabel: {
    id: 'SideNavBar.githubLabel',
    defaultMessage: 'Github',
  },
  githubDesc: {
    id: 'SideNavBar.githubDesc',
    defaultMessage: 'View my Github account',
  },
  settingsLabel: {
    id: 'SideNavBar.settingsLabel',
    defaultMessage: 'Settings',
  },
  settingsDesc: {
    id: 'SideNavBar.settingsDesc',
    defaultMessage: 'Site Settings',
  },
  returnLabel: {
    id: 'SideNavBar.returnLabel',
    defaultMessage: 'Return',
  },
  returnDesc: {
    id: 'SideNavBar.returnDesc',
    defaultMessage: 'Return to home',
  },
});
