import React from 'react';
import styled from 'styled-components';
import barentinePhotographyGif from '../../resources/projectAssets/BarentinePhotography/barentinePhotography.gif';
import { FadeAnimationNoStyles } from '../../common/Styles';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 10px;
  margin: 30px auto;
  background-color: ${({ theme }) => theme.bg};

  box-shadow: ${({ theme }) => theme.box_shadow + ' ' + theme.shadow_color};
  color: ${({ theme }) => theme.color};
`;

const Description = styled.div`
  margin: 50px;
  width: 60%;
  text-align: center;

  @media (max-width: 700px) {
    width: 90%;
  }
`;

const Title = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 26px;
  word-break: break-all;

  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
  @media (max-width: 300px) {
    font-size: 12px;
  }
`;

const GifWrapper = styled.div``;
const Gif = styled.img`
  max-width: 400px;
  width: 100%;
`;

const index = props => {
  return (
    <FadeAnimationNoStyles>
      <DarkmodeReduxSwitch />
      <Container>
        <a href="https://www.barentinephotography.com" target="_blank">
          <Title>
            <FormattedMessage {...messages.website} />
          </Title>
        </a>
        <Description>
          <FormattedMessage {...messages.descriptionOne} />
          
          <br />
          <br />
          <p>
            <FormattedMessage {...messages.descriptionTwo} />
           
          </p>
        </Description>
        <a href="https://www.barentinephotography.com" target="_blank">
          <GifWrapper>
            <Gif src={barentinePhotographyGif}></Gif>
          </GifWrapper>
        </a>
      </Container>
    </FadeAnimationNoStyles>
  );
};

export default index;
