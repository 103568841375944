import React from 'react';
import styled from 'styled-components';
import { FadeAnimation } from '../../common/Styles';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const index = (props) => {
  return (
    <FadeAnimation>
      <Container>{'media feed project'}</Container>
    </FadeAnimation>
  );
};

export default index;
