import { SET_PROJECT_VIEW_INDEX } from '../components/ProjectsPage/constants';

/*
 src/reducers/simpleReducer.js
*/
export default (state = { selectedIndex: null }, action) => {
  switch (action.type) {
    case SET_PROJECT_VIEW_INDEX:
      return {
        selectedIndex: action.payload,
      };
    default:
      return state;
  }
};
