import React from 'react';
import reactCSS from 'reactcss';
import { connect } from 'react-redux';
import { CirclePicker } from 'react-color';

class index extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
  };

  handleClick = () => {
    if (this.props.disabled)
      return
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '40px',
          height: '18px',
          borderRadius: '1px',
          background: this.props.background,
        },
        swatch: {
          padding: '2px',
          background: this.props.color,
          // border: '1px solid black',
          borderRadius: '1px',
          // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: this.props.disabled ? '' : 'pointer',
          opacity: this.props.disabled ? '.3' : '1'
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <CirclePicker color={this.state.color} onChange={this.props.onChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
