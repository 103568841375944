export function selectUserId(state) {
  return state.GameReducer.userId;
}
export function selectPlayers(state) {
  return state.GameReducer.players;
}
// export function selectLanguage(state) {
//   return state.ThemeReducer.language;
// }
// export function selectVariableColor(state) {
//   if (state.ThemeReducer.darkmode) {
//     return state.ThemeReducer.darkmodeConfig;
//   } else {
//     return state.ThemeReducer.lightmodeConfig;
//   }
// }
