import { defineMessages } from 'react-intl';

export default defineMessages({
  website: {
    id: 'BarntinePhotographyPage.website',
    defaultMessage: 'barentinephotography.com',
  },
  descriptionOne: {
    id: 'BarntinePhotographyPage.descriptionOne',
    defaultMessage: 'This website was developed, hosted, and deployed by myself for a local photography business to display their photos.',
  },
  descriptionTwo: {
    id: 'BarntinePhotographyPage.descriptionTwo',
    defaultMessage:
      'The website also features an "admin" section, which allows only authenticated users (the owners of the business) to create, edit, and delete photo galleries which are then reflected live on the website. The site also has multiple staging environments with environment specific variables (api keys, databases, actual data, etc.)',
  },
});
