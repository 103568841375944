import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'BasicGame.title',
    defaultMessage: 'Multiplayer/Realtime Game Summary',
  },
  instructionsHeader: {
    id: 'BasicGame.instructionsHeader',
    defaultMessage: 'Instructions',
  },
  instructions: {
    id: 'BasicGame.instructions',
    defaultMessage: 'Select a player and choose its color by clicking the colored rectangle. Once selected, that player will be disabled as a selectable option across all other devices. The players selectability will be re-enabled after 5 minutes of inactivity. Use the arrow keys to move.',
  },
  descriptionHeader: {
    id: 'BasicGame.descriptionHeader',
    defaultMessage: 'Description',
  },
  description: {
    id: 'BasicGame.description',
    defaultMessage: 'This is a simple game I made to test multi-user connectivity/interactions that would require near realtime responses to the clients. There is no objective, and you can easily test the app by opening 2 brower windows side by side.',
  },
});
