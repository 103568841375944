import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import Button from '../../common/Button';
import { formatTime } from '../../utils/time_helper';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const BodyTitle = styled.h4``;
const Title = styled.h4`
  text-align: center;
  margin: 0px auto;
`;

const Container = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
`;

let selected = '';
let phone = null;
let name = null;
let timeSlotInfo = null;
const index = props => {
  function handleNameChange(event) {
    if (event.target.value.length > 28) return;
    else props.onNameChange(event.target.value);
  }
  function handlePhoneChange(event) {
    if (event.target.value.length > 10) return;
    else props.onPhoneChange(event.target.value);
  }
  if (props.selectedTime) {
    selected = props.selectedTime;
    let selectedStartTime = Object.keys(selected)[0] + '';
    let endTime = +selectedStartTime + 1;
    timeSlotInfo = formatTime(selectedStartTime) + ' to ' + formatTime(endTime);
    name = <input id="nameField" onChange={handleNameChange} onBlur={handleNameChange} type="text" value={props.timeSlotData[selectedStartTime].person.name || ''} />;
    phone = <input id="phoneField" onChange={handlePhoneChange} onBlur={handlePhoneChange} type="phone" value={props.timeSlotData[selectedStartTime].person.phone || ''} />;
  }
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Container>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Title>
              <FormattedMessage {...messages.selectedTimeLabel} /> <strong> {timeSlotInfo}</strong>
            </Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BodyTitle>
            <FormattedMessage {...messages.reserveAppointmentLabel} />
          </BodyTitle>
          <p>
            <label  style={{marginRight: '10px'}}>
              <FormattedMessage {...messages.name} />
            </label>
            {name}
            <br />
            <br />
            <label style={{marginRight: '10px'}}>
              <FormattedMessage {...messages.phone} />
            </label>
            {phone}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button primary onClick={props.onHide}>
            <FormattedMessage {...messages.close} />
          </Button>
        </Modal.Footer>
      </Container>
    </Modal>
  );
};

export default index;
