import { defineMessages } from 'react-intl';

export default defineMessages({
  appRecording: {
    id: 'HQProjectPage.appRecording',
    defaultMessage: 'Application Recording:',
  },
  whatLabel: {
    id: 'HQProjectPage.whatLabel',
    defaultMessage: 'What does it do?',
  },
  whatDesc: {
    id: 'HQProjectPage.whatDesc',
    defaultMessage:
      'As stated above, this app reads in a question, searches it on Google, and compare the results with each of the 3 possible answer choices. A confidence level is then calculated and displayed for the most likely correct answer. This all happens dynamically at run time in an average of about 4 seconds.',
  },
  howLabel: {
    id: 'HQProjectPage.howLabel',
    defaultMessage: 'Project Files/How it Works: ',
  },
  googleScraperLabel: {
    id: 'HQProjectPage.googleScraperLabel',
    defaultMessage: 'GoogleScraper.java',
  },
  googleScraperDesc: {
    id: 'HQProjectPage.googleScraperDesc',
    defaultMessage: ': By using JSOUP java library, this class searches for a key phrase (the question) on google via http get requests',
  },
  screenDataLabel: {
    id: 'HQProjectPage.screenDataLabel',
    defaultMessage: 'ScreenData.java',
  },
  screenDataDesc: {
    id: 'HQProjectPage.screenDataDesc',
    defaultMessage: ': A model class for housing the question information and confidence values',
  },
  textRegionsLabel: {
    id: 'HQProjectPage.textRegionsLabel',
    defaultMessage: 'TextRegions.java',
  },
  textRegionsDesc: {
    id: 'HQProjectPage.textRegionsDesc',
    defaultMessage:
      ": This class uses a third party java library, Tesseract. Tesseract is an OCR (optical character recognition) library which monitors sections of the screen for images containing text. After using some quick math of the devices screen rations, Tesseract will know where to look for the question and is's possible answers. The text is extracted and now ready to search and process.",
  },
  colorChangeLabel: {
    id: 'HQProjectPage.colorChangeLabel',
    defaultMessage: 'ColorChangeChecker.java',
  },
  colorChangeDesc: {
    id: 'HQProjectPage.colorChangeDesc',
    defaultMessage:
      ': Using the Java AWT Robot class, this class is responsible for continuously analyzing 3 unique pixels on the screen of the device playing the game. When these 3 pixels become true (i.e. their colors match predetermined colors which ONLY appear while a question is being displayed, and the 10 second countdown has started), it rejoins the main thread and always the program to continue. Sure, we could have a button to click every time, but why not relax and involve some automation? ',
  },
  driverLabel: {
    id: 'HQProjectPage.driverLabel',
    defaultMessage: 'Driver.java',
  },
  driverDesc: {
    id: 'HQProjectPage.driverDesc',
    defaultMessage: ': The main application entry point - this class houses the UI, search algorithms, and functional maintenance between the containing objects.',
  },
  inRetroLabel: {
    id: 'HQProjectPage.inRetroLabel',
    defaultMessage: 'Problems I ran into, and what I would change if I did it again: ',
  },
  regexWithGoogleLabel: {
    id: 'HQProjectPage.regexWithGoogleLabel',
    defaultMessage: 'REGEX with Google ',
  },
  regexWithGoogleDesc: {
    id: 'HQProjectPage.regexWithGoogleDesc',
    defaultMessage:
      ": An unforeseen consequence of using OCR is the slight variations between text characters which can corrupt the data being fetched. For example, the difference between a tick, ' , and `, as well as different types/fonts of quotation marks, commas, and letters with tilde's or accents over them ended up being a huge problem when it came to accurately searching through the data. While these could be considered corner cases, they happened enough where I had to invest a lot of effort in correcting all possible outcomes.",
  },
  colorChangeCheckerLabel: {
    id: 'HQProjectPage.colorChangeCheckerLabel',
    defaultMessage: 'Using the ColorChangeChecker',
  },
  colorChangeCheckerDesc: {
    id: 'HQProjectPage.colorChangeCheckerDesc',
    defaultMessage:
      ': When the question is displayed on the screen, there are 3 points which have a RGB value of (255, 255, 255). All three of the points reflect this color when and only when a question is appeared. It quickly become apparent that while for some games this was true, for others the value would be an ever slightly different shade of white (with a 254 or 256). This would cause the program to continue waiting for a question instead of executing, and it took a while to find this bug. This was solved by dynamically checking the initial color point at those locations during the first question, and then using that color as the control for the remainder of the game',
  },
  codeMaintainabilityLabel: {
    id: 'HQProjectPage.codeMaintainabilityLabel',
    defaultMessage: 'Code maintainability',
  },
  codeMaintainabilityDesc: {
    id: 'HQProjectPage.codeMaintainabilityDesc',
    defaultMessage:
      'If I could redo this project, I would have invested a lot more time in project structure and functions centered around code reusability (as any good developer should 😅). What I thought would be a smaller project ended up growing really fast, mostly due to so many data and search corrections that needed to be checked for.',
  },
  projectInspirationLabel: {
    id: 'HQProjectPage.projectInspirationLabel',
    defaultMessage: 'Project Inspiration',
  },
  projectInspirationDesc: {
    id: 'HQProjectPage.projectInspirationDesc',
    defaultMessage:
      ' I did not come up with the original concept for this idea. A few months before developing this I came across a ted talk from someone who created a similar application (I can\'t remember his name) - but because he was so bad at the game he developed an app to help him "win" at HQ Trivia. He believed and publicly stated that using it "went against the spirit of the game", and I agree with him completely which is why I\'ve left out a large portion of my the source code, including the search algorithm I created.',
  },
  projectSummaryLabel: {
    id: 'HQProjectPage.projectSummaryLabel',
    defaultMessage: 'HQTrivia Project Summary:',
  },
  wiki: {
    id: 'HQProjectPage.wiki',
    defaultMessage: "If you haven't heard of HQTrivia you can check out their wikipedia page ",
  },
  here: {
    id: 'HQProjectPage.here',
    defaultMessage: 'here',
  },
  summaryDesc: {
    id: 'HQProjectPage.summaryDesc',
    defaultMessage:
      "In short, this application extracts text from predetermined sections of a live video(the question and the possible answers). Once it's extracted the question text, the first x number of result pages are fetched from Google and compared with each of the 3 possible answer choices with a comparison algorithm. A confidence/accuracy level is then calculated and displayed to indicate the most likely correct answer. This all happens live, at run time in an average of about 4 seconds.",
  },
  disclaimer: {
    id: 'HQProjectPage.disclaimer',
    defaultMessage:
      '*For anyone interested in using this code or why I developed this, let me be clear and state that <strong> I do not condone cheating </strong> and I\'ve never used this program to win money on a live game. I created this as a challenge for myself, and to prove that I could create this on my own, without using any outside references. It was used for testing on videos of games I recorded live on my phone, which were later played back for the "live" testing.',
  },
  liveDemoButton: {
    id: 'HQProjectPage.liveDemoButton',
    defaultMessage: 'Live Demo',
  },
  howItWorksButton: {
    id: 'HQProjectPage.howItWorksButton',
    defaultMessage: 'How it Works',
  },
  inRetrospectButton: {
    id: 'HQProjectPage.inRetrospectButton',
    defaultMessage: 'In Retrospect',
  },
  inspirationButton: {
    id: 'HQProjectPage.inspirationButton',
    defaultMessage: 'Inspiration',
  },
});
