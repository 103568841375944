import React, { Component } from 'react';
import styled from 'styled-components';
import * as firebase from 'firebase/app';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 75%;
  justify-content: center;
  margin: 75px auto;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  @media (max-width: 500px) {
    width: 96%;
  }
`;

const QuestionContainer = styled.h5`
  margin: 40px 10px;
  /* border: 1px solid black;
  width: 300px;
  height: 80px;
  border-radius: 40px;
  text-align: center;
  background-color: ${(props) => (props.isAnswer ? '#51c994' : '#FFFFFF')}; */
`;
const PercentageText = styled.div`
  /* border: 1px solid black;
  width: 300px;
  height: 80px;
  border-radius: 40px;
  text-align: center;
  background-color: ${(props) => (props.isAnswer ? '#51c994' : '#FFFFFF')}; */
  font-size: 20px;
  font-weight: bold;
`;
const AnswerText = styled.div`
  /* border: 1px solid black;
  width: 300px;
  height: 80px;
  border-radius: 40px;
  text-align: center;
  background-color: ${(props) => (props.isAnswer ? '#51c994' : '#FFFFFF')}; */
`;
const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 15px;
  border: ${(props) => '2px solid ' + (props.isAnswer ? '#51c994' : '#e5e5e5')};
  width: 100%;
  max-width: 300px;
  height: 65px;
  border-radius: 40px;
  text-align: center;
  background-color: ${(props) => (props.isAnswer ? '#51c994' : '#FFFFFF')};
`;

const COLLECTION_NAME = 'HQ_TRIVIA';
class index extends Component {
  state = {
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option1_confidence: 0,
    option2_confidence: 0,
    option3_confidence: 0,
    answer: 0,
  };

  componentDidMount() {
    const db = firebase.firestore();
    const scope = this;
    db.collection(COLLECTION_NAME)
      .doc('TRIVIA_RESULTS')
      .onSnapshot(function(doc) {
        scope.setState(doc.data());
        scope.updatePercentages();
      });
  }

  updatePercentages = () => {
    const { option1_confidence, option2_confidence, option3_confidence } = {
      ...this.state,
    };
    const opt1 = parseInt(option1_confidence);
    const opt2 = parseInt(option2_confidence);
    const opt3 = parseInt(option3_confidence);
    let answer = 0;

    if (opt1 > opt2 && opt1 > opt3) {
      answer = 1;
    } else if (opt2 > opt3 && opt2 > opt1) {
      answer = 2;
    } else if (opt3 > opt2 && opt3 > opt1) {
      answer = 3;
    }

    this.setState((prevState) => ({ answer }));
  };

  render() {
    const {
      question,
      option1,
      option2,
      option3,
      option1_confidence,
      option2_confidence,
      option3_confidence,
      answer,
    } = { ...this.state };
    return (
      <Container>
        <QuestionContainer>{question}</QuestionContainer>
        <AnswerContainer id="1" isAnswer={answer === 1}>
          <AnswerText>{option1}</AnswerText>
          <PercentageText>{option1_confidence}%</PercentageText>
        </AnswerContainer>
        <AnswerContainer id="2" isAnswer={answer === 2}>
          <AnswerText>{option2}</AnswerText>
          <PercentageText>{option2_confidence}%</PercentageText>
        </AnswerContainer>
        <AnswerContainer id="3" isAnswer={answer === 3}>
          <AnswerText>{option3}</AnswerText>
          <PercentageText>{option3_confidence}%</PercentageText>
        </AnswerContainer>
      </Container>
    );
  }
}

export default index;
