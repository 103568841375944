import axios from 'axios';
import { getRandomNumber } from './utils';
const jsonplaceholderBaseUrl = 'https://jsonplaceholder.typicode.com/photos/';
const categoryUrl = 'http://lorempixel.com/';
const placeholderUrl = 'https://fakeimg.pl/';

export async function getImage(url, param, headers = null) {
  return await axios.get(url + param, {
    headers,
  });
}

export function createPlaceholderUrl(text) {
  const x = getRandomNumber(4, 8) * 100;
  const y = getRandomNumber(4, 8) * 100;
  return {
    src: placeholderUrl + `${x}x${y}?text=${text}&font=lobster`,
    width: x,
    height: y,
  };
}

export async function getPlaceholderImages(numOfImages, text) {
  let images = [];
  for (let index = 0; index < numOfImages; index++) {
    images.push(createPlaceholderUrl(text));
  }
  return images;
}
export function createCategoryUrl(category) {
  const x = getRandomNumber(4, 9) * 100;
  const y = getRandomNumber(4, 9) * 100;
  return {
    src: categoryUrl + `/${x}/${y}/${category}/${getRandomNumber(1, 100)}`,
    width: x,
    height: y,
  };
}

export async function getCategoryImages(numOfImages, category) {
  let images = [];
  for (let index = 0; index < numOfImages; index++) {
    images.push(createCategoryUrl(category));
  }
  return images;
}
export async function getRandomImageUrls(numOfImages) {
  let images = [];
  for (let index = 0; index < numOfImages; index++) {
    let randomNumber = getRandomNumber(1, 4000);
    let response = await getImage(jsonplaceholderBaseUrl, randomNumber);
    images.push({
      src: response.data.url,
      width: 3,
      height: 4,
    });
  }
  return images;
}
