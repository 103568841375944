import { defineMessages } from 'react-intl';

export default defineMessages({
  clickHere: {
    id: 'ProjectsPage.clickHere',
    defaultMessage: 'Click here to view a list of all projects',
  },
  projectsTitle: {
    id: 'ProjectsPage.projectsTitle',
    defaultMessage: 'All Projects',
  },
  projectsDesc: {
    id: 'ProjectsPage.projectsDesc',
    defaultMessage: 'View all Projects',
  },
  cokeTitle: {
    id: 'ProjectsPage.cokeTitle',
    defaultMessage: 'Cokeamizer',
  },
  cokeDesc: {
    id: 'ProjectsPage.cokeDesc',
    defaultMessage: 'Coca-Cola Hackathon',
  },
  deeplinkTitle: {
    id: 'ProjectsPage.deeplinkTitle',
    defaultMessage: 'Deeplink',
  },
  deeplinkDesc: {
    id: 'ProjectsPage.deeplinkDesc',
    defaultMessage: 'Deeplink Test',
  },
  weatherTitle: {
    id: 'ProjectsPage.weatherTitle',
    defaultMessage: 'Weather Forecast',
  },
  weatherDesc: {
    id: 'ProjectsPage.weatherDesc',
    defaultMessage: 'Weather Forecast',
  },
  hqTitle: {
    id: 'ProjectsPage.hqTitle',
    defaultMessage: 'HQ Live Google Scraper',
  },
  hqDesc: {
    id: 'ProjectsPage.hqDesc',
    defaultMessage: 'HQ Trivia Google Scraper',
  },
  barentineTitle: {
    id: 'ProjectsPage.barentineTitle',
    defaultMessage: 'barentinephotography.com',
  },
  barentineDesc: {
    id: 'ProjectsPage.barentineDesc',
    defaultMessage: 'Local Business Website',
  },
  reservationsTitle: {
    id: 'ProjectsPage.reservationsTitle',
    defaultMessage: 'Reservations',
  },
  reservationsDesc: {
    id: 'ProjectsPage.reservationsDesc',
    defaultMessage: 'Make a Reservation',
  },
  settingsTitle: {
    id: 'ProjectsPage.settingsTitle',
    defaultMessage: 'Site Settings',
  },
  settingsDesc: {
    id: 'ProjectsPage.settingsDesc',
    defaultMessage: 'Site Settings',
  },
  basicGameDesc: {
    id: 'ProjectsPage.basicGameDesc',
    defaultMessage: 'Realtime Game',
  },
  basicGameTitle: {
    id: 'ProjectsPage.basicGameTitle',
    defaultMessage: 'Realtime Game',
  },
});
