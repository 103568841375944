/* eslint-disable react/jsx-no-target-blank */
import {
  faCloudSunRain,
  faBookOpen,
  faCamera,
  faTh,
  faUserFriends,
  faGraduationCap,
  faCommentDots,
  faGamepad,
  faList,
  faSpaceShuttle,
  faComments,
  faTree,
  faCogs,
  faSort,
  faSortUp,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import weatherImage from "../../resources/projectAssets/Weather/weather.gif";
import timeSlotsImage from "../../resources/projectAssets/reactTimeSlots/timeSlotHome.gif";
import barentinePhotographyImage from "../../resources/projectAssets/BarentinePhotography/barentinePhotography.gif";
import background from "../../resources/background.jpg";
import hqImage from "../../resources/projectAssets/HQ/HQ1.gif";
import cokeGif from "../../resources/projectAssets/Coke/coke.gif";
import socialFeedImage from "../../resources/projectAssets/Stickies/feed.gif";
import colorSwitchImage from "../../resources/projectAssets/ColorSwitch/home.gif";
import chatAppImage from "../../resources/projectAssets/chatApp/chat.gif";
import todoListImage from "../../resources/projectAssets/TodoList/todoAction.gif";
import messagingImage from "../../resources/projectAssets/chat.gif";
import sceneKitImage from "../../resources/projectAssets/sceneKit.gif";
import spaceImage from "../../resources/projectAssets/space.gif";
import ProjectGallery from "../ProjectGallery";
import AppointmentsProject from "../AppointmentsProject";
import WeatherProject from "../WeatherProject";
import SortingAlgorithms from "../SortingAlgorithms";
import HQProjectPage from "../HQProjectPage";
import ThemeChangeGif from "../../resources/themeChange.gif";
import Sorting from "../../resources/projectAssets/Algorithm/sorting.gif";
import BasicGameGif from "../../resources/projectAssets/BasicGame/BasicGame.gif";
import BarentinePhotographyPage from "../BarentinePhotographyPage";
import MediaFeedProjectPage from "../MediaFeedProjectPage";
import ChatProjectPage from "../ChatProjectPage";
import ColorSwitchProjectPage from "../ColorSwitchProjectPage";
import CokeamizerProjectPage from "../CokeamizerProjectPage";
import DeeplinkProjectPage from "../DeeplinkProjectPage";
import TodoListProjectPage from "../TodoListProjectPage";
import SpaceGameMobileProjectPage from "../SpaceGameMobileProjectPage";
import ChatAppMobileProjectPage from "../ChatAppMobileProjectPage";
import SceneKitDemoProjectPage from "../SceneKitDemoProjectPage";
import Settings from "../Settings";
import BasicGame from "../BasicGame";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

export const SET_PROJECT_VIEW_INDEX = "SET_PROJECT_VIEW_INDEX";

export const ProjectList = [
  {
    title: <FormattedMessage {...messages.projectsTitle} />,
    icon: faTh,
    path: "/projects/all",
    toolTipText: <FormattedMessage {...messages.projectsDesc} />,
    component: ProjectGallery
  },
  {
    title: <FormattedMessage {...messages.settingsTitle} />,
    icon: faCogs,
    path: "/settings",
    toolTipText: <FormattedMessage {...messages.settingsDesc} />,
    previewImage: ThemeChangeGif,
    component: Settings
  },
  {
    title: <FormattedMessage {...messages.cokeTitle} />,
    icon: faQrcode,
    path: "/projects/cokeamizer",
    toolTipText: <FormattedMessage {...messages.cokeDesc} />,
    previewImage: cokeGif,
    component: CokeamizerProjectPage
  },
  {
    title: <FormattedMessage {...messages.hqTitle} />,
    icon: faGraduationCap,
    path: "/projects/hq",
    toolTipText: <FormattedMessage {...messages.hqDesc} />,
    previewImage: hqImage,
    component: HQProjectPage
  },
  // {
  //   title: <FormattedMessage {...messages.deeplinkTitle} />,
  //   icon: faQrcode,
  //   path: "/projects/deeplink",
  //   toolTipText: <FormattedMessage {...messages.deeplinkDesc} />,
  //   previewImage: null,
  //   component: DeeplinkProjectPage
  // },
  {
    title: <FormattedMessage {...messages.weatherTitle} />,
    icon: faCloudSunRain,
    path: "/projects/weather",
    toolTipText: <FormattedMessage {...messages.weatherDesc} />,
    previewImage: weatherImage,
    component: WeatherProject
  },
  {
    title: <FormattedMessage {...messages.barentineTitle} />,
    icon: faCamera,
    path: "/projects/photography",
    toolTipText: <FormattedMessage {...messages.barentineDesc} />,
    previewImage: barentinePhotographyImage,
    component: BarentinePhotographyPage
  },
  {
    title: <FormattedMessage {...messages.reservationsTitle} />,
    icon: faBookOpen,
    path: "/projects/reservations",
    toolTipText: <FormattedMessage {...messages.reservationsDesc} />,
    previewImage: timeSlotsImage,
    component: AppointmentsProject
  },
  {
    title: <FormattedMessage {...messages.basicGameTitle} />,
    icon: faGamepad,
    path: "/game",
    toolTipText: <FormattedMessage {...messages.basicGameDesc} />,
    previewImage: BasicGameGif,
    component: BasicGame
  },
  {
    title: 'Sorting Algorithms',
    icon: faSort,
    path: '/projects/sorting-algorithms',
    toolTipText: 'Sorting Algorithms',
    previewImage: Sorting,
    component: SortingAlgorithms,
  },
  // {
  //   title: 'Online Ordering',
  //   icon: faUtensils,
  //   path: '/projects/onlineOrdering',
  //   toolTipText: 'Online Ordering Project',
  //   previewImage: background,
  //   component: OnlineOrderingProjectPage,
  // },
  // {
  //   title: 'Feed (infinite scroll)',
  //   icon: faUserFriends,
  //   path: '/projects/socialMediaFeed',
  //   toolTipText:
  //     'Social Media Infinite Scroll and Database Injection',
  //   previewImage: socialFeedImage,
  //   component: MediaFeedProjectPage,
  // },
  // {
  //   title: 'User Chat',
  //   icon: faCommentDots,
  //   path: '/projects/chat',
  //   toolTipText: 'Authenticated Messaging Component',
  //   previewImage: messagingImage,
  //   component: ChatProjectPage,
  // },

  // {
  //   title: 'Color Switch App',
  //   icon: faGamepad,
  //   path: '/projects/mobile/colorSwitch',
  //   toolTipText: 'Color Switch (Recreation, iOS)',
  //   previewImage: colorSwitchImage,
  //   component: ColorSwitchProjectPage,
  // },
  // {
  //   title: 'Todo List App',
  //   icon: faList,
  //   path: '/projects/mobile/todo',
  //   toolTipText: 'Todo List (iOS)',
  //   previewImage: todoListImage,
  //   component: TodoListProjectPage,
  // },
  // {
  //   title: 'Space Game App',
  //   icon: faSpaceShuttle,
  //   path: '/projects/mobile/space',
  //   toolTipText: 'Space Game (iOS)',
  //   previewImage: spaceImage,
  //   component: SpaceGameMobileProjectPage,
  // },
  // {
  //   title: 'Chat App',
  //   icon: faComments,
  //   path: '/projects/colorSwitch',
  //   toolTipText: 'Chat App (iOS)',
  //   previewImage: chatAppImage,
  //   component: ChatAppMobileProjectPage,
  // },
  // {
  //   title: 'Scene Kit Demo',
  //   icon: faTree,
  //   path: '/projects/mobile/sceneKitDemo',
  //   toolTipText: 'Scene Kit Demo (iOS)',
  //   previewImage: sceneKitImage,
  //   component: SceneKitDemoProjectPage,
  // }
];
