/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  faMobile,
  faCode,
  faDatabase,
  faLightbulb,
  faCheckCircle,
  faCloud,
  faShieldAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import slideInRight from 'react-animations/lib/slide-in-right';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../../common/Button';
import { BoxShadow } from '../../common';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { faStackOverflow } from '@fortawesome/free-brands-svg-icons';

const animation = keyframes`${slideInRight}`;

const ResumeButton = styled(Button)`
  margin: 0px auto;
`;

const Container = styled.div`
  margin: 0px auto;
  width: 90%;
  text-align: center;
  box-shadow: ${BoxShadow};
  padding: 10px;
  box-sizing: border-box;
  animation: 1s ${animation};
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  margin-top: 30px;
`;
const Paragraph = styled.p`
  margin-top: 30px;
`;

const index = (props) => {
  return (
    <>
      <DarkmodeReduxSwitch />

      <Container>
        <h1 className="h1-responsive font-weight-bold my-3">
          <FormattedMessage {...messages.aboutMe} />
        </h1>

        <a
          href="https://docs.google.com/document/d/1tEP03fjCE1kB3O5rfMTI6SozmiZ69lEEyVpWYn-9vF0/edit?usp=sharing"
          target="_blank"
        >
          <ResumeButton primary>
            <FormattedMessage {...messages.viewMyResume} />
          </ResumeButton>
        </a>

        <Paragraph className="lead grey-text w-responsive mx-auto mb-5">
          <FormattedMessage {...messages.information} />
        </Paragraph>

        <div className="row">
          <div className="col-md-3">
            <IconWrapper>
              <FontAwesomeIcon icon={faLightbulb} size="3x" />
            </IconWrapper>
            <h5 className="font-weight-bold my-4">
              Architecture & Designs:
            </h5>
            <p className="grey-text mb-md-0 mb-5"> </p>
            <div className="text-center mx-auto d-block">
              <div className="text-center mx-auto d-block h6">

                <li>Event-Driven Architecture </li>
                <li>Microservices </li>
                <li>API-First Design</li>
                <li> PCI Compliance</li>
             <li> PII Data Security</li>
             <li> Authentication Best Practices</li>
             <li> Disaster Recovery</li>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <IconWrapper>
              <FontAwesomeIcon icon={faStackOverflow} size="3x" />
            </IconWrapper>
            <h5 className="font-weight-bold my-4">
              Tech Stack/Languages:
            </h5>
            <p className="grey-text mb-md-0 mb-5"> </p>
            <div className="text-center mx-auto d-block">
              <div className="text-center mx-auto d-block h6">
                <li> React</li>
                <li> GraphQL/GraphQL Subscriptions</li>
                <li> Websocket/Webhooks</li>
                <li> Typescript</li>
                <li> Next.js/Nest.js (node)</li>
                <li> C#/.NET</li>
                <li> Java</li>
                <li> SQL/NoSQL</li>
                <li> ORMs (Hibernate, LINQ to SQL)</li>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <IconWrapper>
              <FontAwesomeIcon icon={faCheckCircle} size="3x" />
            </IconWrapper>
            <h5 className="font-weight-bold my-4">
              CI/CD + Cloud Technologies:
            </h5>
            <p className="grey-text mb-md-0 mb-5"> </p>
            <div className="text-center mx-auto d-block">
              <div className="text-center mx-auto d-block h6">
                <li>Unit/Automation Testing</li>
                <li> CI/CD Pipelines</li>
                <li> Terraform</li>
                <li> GKE & Containerization</li>
                <li> Shift Left           </li>
                <li> GCP + Azure </li>
               <li>Scalability</li>
               <li>Availability </li>
               <li>Monitoring</li><li> Terraform      </li> 
                </div>
            </div>
          </div>
          {/* <div className="col-md-3">
            <IconWrapper>
              <FontAwesomeIcon icon={faDatabase} size="3x" />
            </IconWrapper>
            <h5 className="font-weight-bold my-4">
              Database and:
            </h5>
            <p className="grey-text mb-md-0 mb-5"> </p>
            <div className="text-center mx-auto d-block">
              <div className="text-center mx-auto d-block h6">

                   </li></div>
            </div>
          </div> */}
          {/* <div className="col-md-3">
            <IconWrapper>
              <FontAwesomeIcon icon={faCloud} size="3x" />
            </IconWrapper>
            <h5 className="font-weight-bold my-4">
              Cloud Technologies:
            </h5>
            <p className="grey-text mb-md-0 mb-5"> </p>
            <div className="text-center mx-auto d-block">
              <div className="text-center mx-auto d-block h6">
                <li> GCP + Azure </li>
               <li>Scalability</li>
               <li>Availability </li>
               <li>Monitoring</li><li> Terraform      </li>        </div>
            </div>
          </div> */}

          {/* <div className="col-md-3">
            <IconWrapper>
              <FontAwesomeIcon icon={faShieldAlt} size="3x" />
            </IconWrapper>

            <h5 className="font-weight-bold my-4">
              Security & Compliance:            </h5>
            <p className="grey-text mb-md-0 mb-5"></p>

            <div className="text-center mx-auto d-block h6">
            
            <li> PCI Compliance</li>
             <li> PII Data Security</li>
             <li> Authentication Best Practices</li>
             <li> Disaster Recovery</li>
            </div>
          </div> */}

          <div className="col-md-3" style={{padding: '5px'}}>
            <IconWrapper>
              <FontAwesomeIcon icon={faUsers} size="3x" />
            </IconWrapper>
            <h5 className="font-weight-bold my-4">
              Leadership & Management:
            </h5>
            <p className="grey-text mb-0">
            <li> Project Management</li>
            <li> Resource Planning and Allocation </li>
            
             <li> Mentoring</li>
             <li> Cross-Functional Collaboration</li>
             <li> Strategic Planning</li>

             <li> International Team Coordination        </li>    </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default index;
