import React, { Component } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SideNavBar from '../SideNavBar';
import GalleriesHome from '../GalleriesHome';
import PageHeader from '../PageHeader';
import ProjectsPage from '../ProjectsPage';
import AboutPage from '../AboutPage';
import Settings from '../Settings';
import HQListener from '../HQListener';
import ThreeJS from '../ThreeJS';
import { connect } from 'react-redux';
import { setGalleryIndex } from '../GalleriesHome/actions';
import { ProjectList } from '../ProjectsPage/constants';
import { ThemeProvider } from 'styled-components';
import { selectDarkmode, selectDarkmodeSettings, selectLightmodeSettings } from '../../common/Theme/selectors';
import firebase from 'firebase/app';
import 'firebase/auth';
import { IntlProvider } from 'react-intl';
import { getFirebaseSettings } from '../Settings/actions';
import en from '../../translations/en.json';
import zn from '../../translations/zn.json';
import { selectLanguage } from '../Settings/selectors';

const Wrapper = styled.div`
  min-height: 100vh;
  margin-left: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
`;

const TopPadding = styled.div``;

class index extends Component {
  componentDidMount() {
    this.subscribeToAuthChange();
  }

  subscribeToAuthChange = () => {
    const scope = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        scope.props.getSettings(user.uid);
      } else {
        // No user is signed in.
      }
    });
  };

  routes = ProjectList.map(project => <Route key={project.path} path={project.path} component={project.component} />);
  render() {
    return (
      <IntlProvider locale="en" messages={this.props.selectLanguage === 'en' ? en : zn}>
        <ThemeProvider theme={this.props.isDarkmode ? this.props.selectDarkModeConfig : this.props.selectLightModeConfig}>
          <Router>
            <SideNavBar />
            <Wrapper>
              <TopPadding />
              <Switch>
                <Route exact path="/" component={PageHeader} />
                <Route exact path="/projects" component={ProjectsPage} />
                <Route path="/galleries" component={GalleriesHome} />
                <Route path="/about" component={AboutPage} />
                <Route path="/settings" component={Settings} />
                <Route path="/hq" component={HQListener} />
                <Route path="/threejs" component={ThreeJS} />
                {this.routes}
                <Redirect to="/" />
              </Switch>
            </Wrapper>
          </Router>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setGalleryIndex: index => dispatch(setGalleryIndex(index)),
  //todo this should be set
  getSettings: userId => dispatch(getFirebaseSettings(userId)),
});
const mapStateToProps = state => ({
  isDarkmode: selectDarkmode(state),
  selectDarkModeConfig: selectDarkmodeSettings(state),
  selectLightModeConfig: selectLightmodeSettings(state),
  selectLanguage: selectLanguage(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
