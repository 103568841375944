import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'PageHeader.Name',
    defaultMessage: 'Chris Anderson',
  },
  jobTitle: {
    id: 'PageHeader.JobTitle',
    defaultMessage: 'Software Engineer',
  },
  customize: {
    id: 'PageHeader.customize',
    defaultMessage: 'Click here to customize this website',
  },
});
