import React, { Component } from 'react';
import styled from 'styled-components';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import Button from '../../common/Button';
import { getRandomNumber } from '../../utils/utils';
import { FadeAnimationNoStyles } from '../../common/Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
  margin: 0px auto;
  width: 75%;
  height: 75%;
  height: 600px;
  flex-direction: column;
`;

const GraphContainer = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 80%;
  width: 90%;
  justify-content: center;
`;

const InfoContainer = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  font-weight: bold;
  font-size: 30px;
  height: 50%;
  width: 50%;
  text-align: center;
`;

const TitleContainer = styled.div`
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  font-size: 20px;
  height: 50%;
  width: 50%;
  text-align: center;
`;

const GraphBar = styled.div`
  width: 3px;
  height: ${({ height }) => height};
  margin: 0px 1px;
  background-color: pink;
  background-color: ${({ highlight }) => (highlight === true ? 'green' : '')};
  background-color: ${({ isPivot }) => (isPivot === true ? 'blue' : '')};
  background-color: ${({ color }) => (color !== false ? color : '')};
`;

const Check = styled.div`
  color: green;
`;
const X = styled.div`
  color: red;
`;

class index extends Component {
  state = {
    arrayLength: 100,
    array: [],
    previousArray: [],
    timeout: 100,
    isSorting: false,
    swapping: false,
  };

  async componentDidMount() {
    await this.shuffle();
    // await this.cycle();
    await this.quickSortAlias()
  }

  shuffle = async () => {
    const newArray = this.generateArray();
    this.setState({
      graphArray: newArray,
      previousArray: newArray,
    });
  };

  generateArray = () =>
    Array.from({ length: this.state.arrayLength }, (_, i) => {
      const value = getRandomNumber(100, 400);
      return <GraphBar height={value + 'px'} width={'3px'} value={value} key={i} id={i} />;
    });

  cycle = () => {
    this.setState({ isSorting: true });
    const originalArray = [...this.state.graphArray];
    var i = 0,
      howManyTimes = this.state.arrayLength;
    const scope = this;
    function f() {
      let ColorChangeBar = React.cloneElement(scope.state.graphArray[i], { highlight: true });
      // const previousGraph = scope.state.graphArray;
      const previousGraph = [...originalArray];
      previousGraph[i] = ColorChangeBar;
      scope.setState({
        graphArray: previousGraph,
      });
      i++;
      if (i < howManyTimes) {
        setTimeout(f, 1);
      } else {
        scope.setState({ isSorting: false });
      }
    }
    f();
  };

  highlight = async (i, isPivot = false) => {
    let ColorChangeBar = React.cloneElement(this.state.graphArray[i], { highlight: true, isPivot, color: isPivot });
    const previousGraph = [...this.state.graphArray];
    previousGraph[i] = ColorChangeBar;
    await this.updateArray(previousGraph);
    // return previousGraph;
  };

  removeHighlight = () => {
    const previousGraph = [...this.state.graphArray];
    for (let index = 0; index < this.state.graphArray.length; index++) {
      previousGraph[index] = React.cloneElement(this.state.graphArray[index], { highlight: false });
    }
    this.setState({
      graphArray: previousGraph,
    });
  };

  updateArray = graphArray => {
    this.setState({ graphArray });
  };

  swap = async (items, leftIndex, rightIndex) => {
    await this.wait(500);
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
    // console.log('swapping...');
    // await this.wait(1000);
    this.updateArray(items);

    // this.removeHighlight();
    // await this.wait(1000);
  };

  partition = async (items, left, right) => {
    const pivotElement = items[Math.floor((right + left) / 2)];
    var pivot = parseInt(pivotElement.props.value), //middle element
      i = left, //left pointer
      j = right; //right pointer
    // this.highlight(right);

    // console.log(`i: ${i}`);
    // console.log(`j: ${j}`);
    while (i <= j) {
      while (parseInt(items[i].props.value) < pivot) {
        // console.log(`i++ :${i}`);
        // this.highlight(i);
        console.log('add i');
        await this.setState({
          pivot: pivotElement.props.value,
          left: items[i].props.value,
        });
        await this.highlight(i, 'orange');
        await this.highlight(pivotElement.props.id, true);
        await this.wait(this.state.timeout);
        i++;
      }
      while (parseInt(items[j].props.value) > pivot) {
        await this.setState({
          pivot: pivotElement.props.value,
          right: items[j].props.value,
        });
        console.log('minus j');
        await this.highlight(j, 'purple');
        await this.highlight(pivotElement.props.id, true);
        await this.wait(this.state.timeout);
        j--;
      }
      if (i <= j) {
        console.log('swap');
        //swap two elements
        await this.highlight(i, 'yellow');
        await this.highlight(j, 'red');
        await this.highlight(pivotElement.props.id, true);
        await this.setState({
          pivot: pivotElement.props.value,
          left: items[i].props.value,
          right: items[j].props.value,
          swapping: false,
        });
        await this.wait(500);
        await this.swap(items, i, j);
        i++;
        j--;
      }
    }
    return i;
  };

  wait = ms => new Promise((r, j) => setTimeout(r, ms));

  quickSortAlias = () => {
    console.log('quickSortAlias');
    this.quickSort(this.state.graphArray, 0, this.state.arrayLength - 1);
  };

  quickSort = async (items, left, right) => {
    // console.log('items');
    // console.log(items);
    var index;
    if (items.length > 1) {
      // console.log('sorting...');
      index = await this.partition(items, left, right); //index returned from partition
      if (left < index - 1) {
        // console.log('if (left < index - 1');
        //more elements on the left side of the pivot
        await this.quickSort(items, left, index - 1);
      }
      if (index < right) {
        // console.log('if (index < right');

        //more elements on the right side of the pivot
        await this.quickSort(items, index, right);
      }
    }
    return items;
  };

  render() {
    return (
      <FadeAnimationNoStyles>
        <DarkmodeReduxSwitch />
        <Container>
        <TitleContainer> 
            <div> A simple visualization of the quick sort algorithm. This is pure CSS being re-rendered and slowed to show the comparison process. Refreshing the page will generate a new, random set.</div>
          </TitleContainer> 

          <GraphContainer>
            {this.state.graphArray}
          </GraphContainer>
          <InfoContainer>
            <div>Pivot = {this.state.pivot}</div>
            <div>Left = {this.state.left}</div>
            <div>Right = {this.state.right}</div>
            <div>
              Is {this.state.left} {'<='} {this.state.pivot}?
              {this.state.left <= this.state.pivot ? (
                <Check>
                  <FontAwesomeIcon icon={faCheck} size="2x"></FontAwesomeIcon>
                </Check>
              ) : (
                <X>
                  <FontAwesomeIcon icon={faTimes} size="2x"></FontAwesomeIcon>
                </X>
              )}
            </div>
          </InfoContainer>
        </Container>
      </FadeAnimationNoStyles>
    );
  }
}

export default index;
