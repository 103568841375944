import { defineMessages } from 'react-intl';

export default defineMessages({
  hello: {
    id: 'RunningStickMan.hello',
    defaultMessage: "Hello! Please don't poke me.",
  },
  hey: {
    id: 'RunningStickMan.hey',
    defaultMessage: 'Hey!',
  },
  cutItOut: {
    id: 'RunningStickMan.cutItOut',
    defaultMessage: 'Cut it out!',
  },
  stop: {
    id: 'RunningStickMan.stop',
    defaultMessage: 'Stop!',
  },
  quit: {
    id: 'RunningStickMan.quit',
    defaultMessage: 'Quit!',
  },
  goAway: {
    id: 'RunningStickMan.goAway',
    defaultMessage: 'Go Away!',
  },
  why: {
    id: 'RunningStickMan.why',
    defaultMessage: 'Why are you doing this to me??',
  },
});
