import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { SlideInRightAnimationNoStyles } from '../../common/Styles';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import ColorPicker from '../ColorPicker';
import SignInFirebase from '../SignInFirebase';
import Button from '../../common/Button';
import Spinner from '../../common/Spinner';
import { BoxShadow } from '../../common';
import { selectVariableColor, selectIsFirebaseDataLoading } from './selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faBicycle, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';
import 'firebase/auth';
import { resetThemeSettings, setLanguage } from './actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  margin: 0px auto;
  box-shadow: ${BoxShadow};
  background-color: ${({ theme }) => theme.bg_lighter};
  @media (max-width: 800px) {
    padding-left: 0px;
    padding-right: 0px;
    width: 98%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Wrapper = styled(SlideInRightAnimationNoStyles)`
  padding-bottom: 30px;
`;
const StyledButton = styled(Button)`
  margin: 10px;
`;

const AlertContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  opacity: ${({ showAlert }) => (showAlert ? 0.8 : 0.0)};
  transition: all 250ms ease;
`;

const SettingsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px auto;
  flex-direction: column;
  justify-content: flex-center;
  justify-items: flex-start;
`;

const ExamplesWrapper = styled.div`
  display: block;
  flex-wrap: wrap;
  margin: 0px auto;
  flex-direction: column;
  justify-content: flex-start;
  word-break: break-all;
  align-items: center;
  width: 100%;
`;
const Setting = styled.div`
  height: 30px;
  max-width: 220px;
  width: 100%;
  margin: 6px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

const TextContainer = styled.div`
  color: ${({ theme }) => theme.color};
  max-width: 400px;
  box-shadow: ${({ theme }) => theme.box_shadow + ' ' + theme.shadow_color};
  padding: 10px;
`;

const Padding = styled.div`
  height: 80px;
`;
const IconContainer = styled(TextContainer)`
  display: flex;
  max-width: 300px;
  margin: 0px auto;
  justify-content: center;
  flex-direction: row;
  color: ${({ theme }) => theme.icon_color};
  margin-bottom: 20px;
`;

const SectionLabel = styled.h4`
  color: ${({ theme }) => theme.color};
  text-align: center;
  margin-top: 15px;
`;

const IconWrapper = styled.div`
  margin: 10px;
  &:hover {
    color: ${props => props.theme.icon_hover_color};
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const ExampleItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 15px 0px;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.color};
  margin-right: 10px;
`;

const Header = styled.h2`
  margin-top: 15px;
  text-align: center;
  color: ${({ theme }) => theme.color};
`;

const ColorHeader = styled.h2`
  width: 200px;
  margin: 15px auto;
  text-align: end;
  color: ${({ theme }) => theme.color};

  @media (max-width: 500px) {
    text-align: center;
  }
`;
const HeaderSmall = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.color};
`;
const ResetButton = styled(Button)`
  margin: 30px;
  background-color: '#6699cc';

  &:hover {
    background-color: '#3c648c';
  }
`;

const LanguagePicker = styled.div`
  position: absolute;
  height: 30px;
  top: 0;
  right: 0;
  left: 1;
  bottom: 0;
  z-index: 999;
`;
const Language = styled.span`
  margin: 3px;
  &:hover {
    cursor: pointer;
    color: gray;
    color: ${props => props.theme.icon_hover_color};
    cursor: pointer;
    transform: scale(1.1);
  }
  transition: 250ms ease all;
`;

const settingVariables = [
  // { title: 'Background:', variable: 'bg' },
  { title: <FormattedMessage {...messages.background} />, variable: 'bg' },
  { title: <FormattedMessage {...messages.backgroundLighter} />, variable: 'bg_lighter' },
  { title: <FormattedMessage {...messages.text} />, variable: 'color' },
  { title: <FormattedMessage {...messages.button} />, variable: 'main_button_primary' },
  { title: <FormattedMessage {...messages.buttonHover} />, variable: 'main_button_primary_darker' },
  { title: <FormattedMessage {...messages.loadingElement} />, variable: 'button_spinner_color' },
  { title: <FormattedMessage {...messages.shadow} />, variable: 'shadow_color' },
  { title: <FormattedMessage {...messages.icon} />, variable: 'icon_color' },
  { title: <FormattedMessage {...messages.iconHover} />, variable: 'icon_hover_color' },
];

class index extends Component {
  state = {
    alertDetails: {
      showAlert: false,
      variant: 'primary',
      message: 'initial message',
    },
    isFirebaseDataLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.isFirebaseDataLoading !== nextProps.isFirebaseDataLoading) {
      this.setState({ isFirebaseDataLoading: nextProps.isFirebaseDataLoading });
    }
  }

  activateAlert = (variant, message) => {
    this.setState({
      alertDetails: {
        variant,
        message,
        showAlert: true,
      },
    });

    setTimeout(() => {
      this.setState({
        alertDetails: {
          variant: 'success',
          message: '',
          showAlert: false,
        },
      });
    }, 3500);
  };
  render() {
    const settings = settingVariables.map((settingObject, index) => (
      <Setting>
        <Label>{settingObject.title}</Label>
        <ColorPicker variable={settingObject.variable} background={this.props.selectSettingColor[settingObject.variable]} color={this.props.selectSettingColor['color']} />
      </Setting>
    ));
    return (
      <Wrapper>
        <DarkmodeReduxSwitch />
        <LanguagePicker>
          <Language onClick={() => this.props.setLanguage('en')}>EN</Language> | <Language onClick={() => this.props.setLanguage('zn')}>中文</Language>
        </LanguagePicker>
        <ResetButton
          primary
          onClick={() => {
            this.props.resetTheme();
            this.activateAlert('success', <FormattedMessage {...messages.saveAlertMessage} />);
          }}
        >
          <FormattedMessage {...messages.resetToDefaults} />
        </ResetButton>
        <HeaderSmall>
          <FormattedMessage {...messages.anyChangesHere} />
        </HeaderSmall>
        <SignInFirebase />
        <Container>
          <SettingsWrapper>
            <ColorHeader>
              <FormattedMessage {...messages.colorsLabel} />
            </ColorHeader>
            {this.props.isFirebaseDataLoading ? (
              <>
                <Padding />
                <Spinner />
              </>
            ) : (
              settings
            )}
          </SettingsWrapper>

          <ExamplesWrapper>
            <Header>
              <FormattedMessage {...messages.examplesLabel} />
            </Header>
            <SectionLabel>
              <FormattedMessage {...messages.buttonsLabel} />
            </SectionLabel>

            <ExampleItem>
              <StyledButton primary>
                <FormattedMessage {...messages.primaryButtonText} />
              </StyledButton>
              <StyledButton>
                <FormattedMessage {...messages.secondaryButtonText} />
              </StyledButton>
            </ExampleItem>
            <ExampleItem>
              <StyledButton primary isLoading />
            </ExampleItem>
            <SectionLabel>
              <FormattedMessage {...messages.textLabel} />
            </SectionLabel>

            <ExampleItem>
              <TextContainer>
                <p>
                  <FormattedMessage {...messages.loremIpsum} />
                </p>
              </TextContainer>
            </ExampleItem>
            <SectionLabel>
              <FormattedMessage {...messages.iconsLabel} />
            </SectionLabel>

            <IconContainer>
              <IconWrapper>
                <FontAwesomeIcon icon={faAccessibleIcon} size="2x" />
              </IconWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faExchangeAlt} size="2x" />
              </IconWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faBicycle} size="2x" />
              </IconWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faBirthdayCake} size="2x" />
              </IconWrapper>
            </IconContainer>
          </ExamplesWrapper>
        </Container>
        <AlertContainer showAlert={this.state.alertDetails.showAlert}>
          <Alert variant={this.state.alertDetails.variant}>{this.state.alertDetails.message}</Alert>
        </AlertContainer>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetTheme: () => dispatch(resetThemeSettings()),
  setLanguage: language => dispatch(setLanguage(language)),
});

const mapStateToProps = state => ({
  selectSettingColor: selectVariableColor(state),
  isFirebaseDataLoading: selectIsFirebaseDataLoading(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
