import Axios from 'axios';
import { API_KEY, FIVE_DAY_FORECAST_BASE } from './constants';
import { faSun, faCloudSunRain, faSnowflake, faCloudSun } from '@fortawesome/free-solid-svg-icons';
var converter = require('number-to-chinese-words');

export async function fetchWeatherData(zip, language) {
  let url = FIVE_DAY_FORECAST_BASE;
  let currentLanguage = language === 'en' ? '' : '&lang=zh_cn';
  let units = language === 'en' ? '&units=imperial' : '&units=metric';
  let response = await Axios.get(url + zip + units + currentLanguage + API_KEY, {
    headers: null,
  });
  return response.data;
}

export function extractDetails(data, language) {
  let forecastData = [];
  let currentWeatherDataDayIndex = 0;
  let currentDate = new Date().toISOString().split('T')[0];
  let currentMin = null;
  let currentMax = null;
  const cityName = data.city.name;

  for (let index = 0; index < data.cnt; index++) {
    let currentWeatherModel = {};
    if (currentMin === null) {
      currentMin = data.list[index].main.temp_min;
      currentMax = data.list[index].main.temp_max;
    }
    if (data.list[index].main.temp_min < currentMin) {
      currentMin = data.list[index].main.temp_min;
    }

    if (data.list[index].main.temp_max > currentMax) {
      currentMax = data.list[index].main.temp_max;
    }

    if (data.list[index].dt_txt.substring(0, 10) !== currentDate.substring(0, 10)) {
      currentWeatherModel.description = data.list[index].weather[0].description;
      currentWeatherModel.icon = getIcon(data.list[index].weather[0].main);
      currentWeatherModel.imageUrl = getImage(data.list[index].weather[0].main);
      currentWeatherModel.low = currentMin;
      currentWeatherModel.high = currentMax;
      currentWeatherModel.date = new Date(data.list[index].dt_txt.substring(0, 10)).toDateString().substring(0, 10);
      currentDate = data.list[index].dt_txt;
      forecastData.push(currentWeatherModel);
      currentMin = null;
      currentMax = null;

      if (language === 'zn') {
        currentWeatherModel.date = chineseConversion(currentWeatherModel.date);
      }


      //set to the next day
      let nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + (currentWeatherDataDayIndex + 1));
      currentDate = nextDay.toISOString().split('T')[0];
      currentWeatherDataDayIndex++;
    }
  }
  return { city: cityName, data: forecastData };
}

function getImage(main) {
  switch (main) {
    case 'Rain':
      return 'https://openclipart.org/download/170675/showers.svg';
    case 'Clouds':
      return 'https://openclipart.org/download/170679/sunny-to-cloudy.svg';
    case 'Snow':
      return 'https://openclipart.org/download/218651/weather-heavy-snow.svg';
    default:
      return 'https://openclipart.org/download/170678/sunny.svg';
  }
}
function getIcon(main) {
  switch (main) {
    case 'Rain':
      return faCloudSunRain;
    case 'Clouds':
      return faCloudSun;
    case 'Snow':
      return faSnowflake;
    default:
      return faSun;
  }
}
//Thu Nov 07
function chineseConversion(date) {
  let weekDay = date.substring(0, 3);
  let month = date.substring(4, 7);
  let day = date.substring(8, 10);

  switch (weekDay) {
    case 'Mon':
      weekDay = '星期一';
      break;
    case 'Tue':
      weekDay = '星期二';
      break;
    case 'Wed':
      weekDay = '星期三';
      break;
    case 'Thu':
      weekDay = '星期四';
      break;
    case 'Fri':
      weekDay = '星期五';
      break;
    case 'Sat':
      weekDay = '星期六';
      break;
    case 'Sun':
      weekDay = '星期天';
      break;
    default:
      break;
  }

  switch (month) {
    case 'Jan':
      month = '一月';
      break;
    case 'Feb':
      month = '二月';
      break;
    case 'Mar':
      month = '三月';
      break;
    case 'Apr':
      month = '四月';
      break;
    case 'May':
      month = '五月';
      break;
    case 'Jun':
      month = '六月';
      break;
    case 'Jul':
      month = '七月';
      break;
    case 'Aug':
      month = '八月';
      break;
    case 'Sep':
      month = '九月';
      break;
    case 'Oct':
      month = '十月';
      break;
    case 'Nov':
      month = '十一月';
      break;
    case 'Dec':
      month = '十二月';
      break;
    default:
      break;
  }

  day = converter.toWords(day);
  return weekDay + ' ' + month + day + '号';
}
