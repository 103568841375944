import * as firebase from 'firebase/app';

export const updateLocation = async (userId, data) => {
  const db = firebase.firestore();
  try {
    db.collection('game')
      .doc(userId)
      .set(
        {
          ...data,
        },
        { merge: true },
      )
      .then(function () {
      })
      .catch(function (error) {
        // scope.activateAlert('danger', 'Error creating document!');
        console.log(error)

      });
  } catch (error) {
    // this.activateAlert('danger', 'Error creating document!');
    console.log(error)

  }
};

export const getSettings = async (collectionName, docId) => {
  const db = firebase.firestore();

  // try {
  //   db.collection('users')
  //     .doc(collectionName)
  //     .set(
  //       {
  //         ...data,
  //       },
  //       { merge: true },
  //     )
  //     .then(function () {
  //       // db.storage()
  //       //   .ref(`siteSettings/test`)
  //       //   .child();
  //       // scope.activateAlert('success', 'Document successfully written!');
  //       // scope.fetchGalleryData();
  //       // scope.setState({
  //       //   titleInputValue: 'New Gallery',
  //       //   srcValues: ['https://fakeimg.pl/440x320/282828/eae0d0/?retina=1'],
  //       // });
  //     })
  //     .catch(function (error) {
  //       // scope.activateAlert('danger', 'Error creating document!');
  //     });
  // } catch (error) {
  //   // this.activateAlert('danger', 'Error creating document!');
  // }


  var docRef = db.collection(collectionName).doc(docId);

  return await docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        console.log('Document data:', doc.data());
        return doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch(function (error) {
      console.log('Error getting document:', error);
    });
};
