/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import styled from 'styled-components';
import { FadeAnimationNoStyles } from '../../common/Styles';
import Button from '../../common/Button';
import HQ1 from '../../resources/projectAssets/HQ/HQ1.gif';
import HQ2 from '../../resources/projectAssets/HQ/HQ2.gif';
import DarkmodeReduxSwitch from '../DarkmodeReduxSwitch';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import messages from './messages';
const FADE_TIME = '300ms';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  width: 95%;
  button:focus {
    outline: 0;
  }
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.color};
`;
const InformationContainer = styled.div`
  margin: 0px auto;
  width: 70%;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

const Image = styled.img`
  margin: 0px auto;
  width: 100%;
  max-width: 450px;
  height: 100%;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;
const ImageContainer = styled.div`
  margin: 20px;
  display: flex;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  justify-content: space-evenly;
`;
const Header = styled.h3`
  text-align: center;
  margin: 20px;
`;

const Buttons = styled(Button)`
  background: ${props => (props.isActive ? props.theme.main_button_primary : props.theme.bg)};
  color: ${props => (props.isActive ? props.theme.active_button_text_color : props.theme.button_text_color)};
  height: 40px;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
`;

const LiveDemo = props => {
  return (
    <FadeAnimationNoStyles time={FADE_TIME}>
      <Header>
        <FormattedMessage {...messages.appRecording} />
      </Header>
      <ImageContainer>
        <Image src={HQ1} />
      </ImageContainer>
      <ImageContainer>
        <Image src={HQ2} />
      </ImageContainer>
    </FadeAnimationNoStyles>
  );
};
const HowItWorks = props => {
  return (
    <FadeAnimationNoStyles time={FADE_TIME}>
      <h3>
        <FormattedMessage {...messages.whatLabel} />
      </h3>

      <p class="grey-text">
        <FormattedMessage {...messages.whatDesc} />
      </p>
      <hr />
      <h3>
        <FormattedMessage {...messages.howLabel} />
      </h3>
      <ul>
        <li>
          <strong>
            <FormattedMessage {...messages.googleScraperLabel} />
          </strong>
          <FormattedMessage {...messages.googleScraperDesc} />
        </li>
        <li>
          <strong>
            <FormattedMessage {...messages.screenDataLabel} />
          </strong>
          <FormattedMessage {...messages.screenDataDesc} />
        </li>
        <li>
          <strong>
            <FormattedMessage {...messages.textRegionsLabel} />
          </strong>
          <FormattedMessage {...messages.textRegionsDesc} />
        </li>
        <li>
          <strong>
            <FormattedMessage {...messages.colorChangeLabel} />
          </strong>
          <FormattedMessage {...messages.colorChangeDesc} />
        </li>
        <li>
          <strong>
            <FormattedMessage {...messages.driverLabel} />
          </strong>
          <FormattedMessage {...messages.driverDesc} />
        </li>
      </ul>
    </FadeAnimationNoStyles>
  );
};
const InRetrospect = props => {
  return (
    <FadeAnimationNoStyles time={FADE_TIME}>
      <h3>
        <FormattedMessage {...messages.inRetroLabel} />
      </h3>
      <ul>
        <li>
          <strong>
            <FormattedMessage {...messages.regexWithGoogleLabel} />
          </strong>
          <FormattedMessage {...messages.regexWithGoogleDesc} />
        </li>
        <li>
          <strong>
            <FormattedMessage {...messages.colorChangeCheckerLabel} />
          </strong>
          <FormattedMessage {...messages.colorChangeCheckerDesc} />
        </li>
        <li>
          <strong>
            <FormattedMessage {...messages.codeMaintainabilityLabel} />
          </strong>
          :
          <FormattedMessage {...messages.codeMaintainabilityDesc} />
        </li>
      </ul>
    </FadeAnimationNoStyles>
  );
};
const Inspiration = props => {
  return (
    <FadeAnimationNoStyles time={FADE_TIME}>
      <h3>
        <FormattedMessage {...messages.projectInspirationLabel} />
      </h3>
      <p class="grey-text">
        <FormattedMessage {...messages.projectInspirationDesc} />
      </p>
    </FadeAnimationNoStyles>
  );
};

class index extends Component {
  numOfButtons = [
    <FormattedMessage {...messages.liveDemoButton} />,
    <FormattedMessage {...messages.howItWorksButton} />,
    <FormattedMessage {...messages.inRetrospectButton} />,
    <FormattedMessage {...messages.inspirationButton} />,
  ];
  state = { currentPageIndex: 0 };

  toggleButtonView = currentPageIndex => {
    this.setState({ currentPageIndex });
  };

  render() {
    const hqNavButtons = this.numOfButtons.map((title, index) => (
      <Buttons key={title} isActive={this.state.currentPageIndex === index} onClick={() => this.toggleButtonView(index)}>
        {title}
      </Buttons>
    ));
    return (
      <FadeAnimationNoStyles>
        <DarkmodeReduxSwitch />
        <Container>
          <Header>
            <FormattedMessage {...messages.projectSummaryLabel} />
          </Header>
          <p className="grey-tex">
            <i>
              <FormattedMessage {...messages.wiki} />
              <a href="https://en.wikipedia.org/wiki/HQ_Trivia" target="_blank">
                <FormattedMessage {...messages.here} />
              </a>
              .
            </i>
          </p>
          <br />
          <p class="grey-text">
            <FormattedMessage {...messages.summaryDesc} />
          </p>
          <br />
          <i class="grey-text">
            <FormattedHTMLMessage {...messages.disclaimer} />
          </i>
          <ButtonContainer>{hqNavButtons}</ButtonContainer>
          {this.state.currentPageIndex === 0 ? <LiveDemo /> : null}
          <InformationContainer>
            {this.state.currentPageIndex === 1 ? <HowItWorks /> : null}
            {this.state.currentPageIndex === 2 ? <InRetrospect /> : null}
            {this.state.currentPageIndex === 3 ? <Inspiration /> : null}
          </InformationContainer>
        </Container>
      </FadeAnimationNoStyles>
    );
  }
}

export default index;
