import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setDarkmode } from '../../common/Theme/actions';
import { selectDarkmode } from '../../common/Theme/selectors';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Container = styled.div`
  display: flex;
  position: inherit;
  padding-top: 15px;
  padding-left: 10px;
  z-index: 1000;
  color: ${({ theme }) => theme.color};
`;
const CheckBoxWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
const CheckBoxLabel = styled.label`
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

class index extends Component {
  toggleCheckBox = () => {
    this.props.toggleDarkmode();
  };
  render() {
    return (
      <Container>
        <label>
          <FormattedMessage {...messages.label} />
        </label>
        <CheckBoxWrapper>
          <CheckBox id="checkbox" type="checkbox" onChange={this.toggleCheckBox} checked={this.props.selectDarkmode} />
          <CheckBoxLabel htmlFor="checkbox" />
        </CheckBoxWrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toggleDarkmode: index => dispatch(setDarkmode(index)),
});

const mapStateToProps = state => ({
  selectDarkmode: selectDarkmode(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
