import * as actionTypes from './actions';

const initialState = {
  result: 5,
  startTime: 9,
  endTime: 17,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_INITIAL_DATA: {
      let timeSlots = {};
      let i = state.startTime;
      while (i < state.endTime) {
        timeSlots = {
          ...timeSlots,
          [i]: {
            reserved: false,
            person: {
              phone: null,
              name: null,
            },
          },
        };
        i++;
      }

      return {
        ...state,
        timeSlots: timeSlots,
      };
    }

    case actionTypes.ON_NAME_CHANGED:
      var reservedBoolean;
      var index = Object.keys(state.selectedTimeSlot)[0];
      var timeSlots = {
        ...state.timeSlots,
      };
      var person = timeSlots[index].person.phone + '';

      if (person.trim().length > 0 || action.payload.text !== '') {
        reservedBoolean = true;
      } else {
        reservedBoolean = false;
      }

      timeSlots[index].person = {
        ...timeSlots[index].person,
        name: action.payload.text,
      };
      timeSlots[index] = {
        ...timeSlots[index],
        reserved: reservedBoolean,
      };

      return {
        ...state,
        timeSlots,
      };

    case actionTypes.ON_PHONE_CHANGED:
      var index = Object.keys(state.selectedTimeSlot)[0];
      var reservedBoolean;
      var timeSlots = {
        ...state.timeSlots,
      };
      var person = timeSlots[index].person.name + '';
      if (person.trim().length > 0 || action.payload.text !== '') {
        reservedBoolean = true;
      } else {
        reservedBoolean = false;
      }

      timeSlots[index].person = {
        ...timeSlots[index].person,
        phone: action.payload.text,
      };
      timeSlots[index] = {
        ...timeSlots[index],
        reserved: reservedBoolean,
      };

      return {
        ...state,
        timeSlots,
      };
    case actionTypes.TIME_SLOT_ITEM_PRESSED:
      return {
        ...state,
        selectedTimeSlot: {
          [action.payload]: state.timeSlots[action.payload],
        },
      };
    default: {
      return { ...state };
    }
  }
};

export default reducer;
