import { defineMessages } from 'react-intl';

export default defineMessages({
  selectedTimeLabel: {
    id: 'AppointmentModal.selectedTimeLabel',
    defaultMessage: "You've selected the time slot from ",
  },
  reserveAppointmentLabel: {
    id: 'AppointmentModal.reserveAppointmentLabel',
    defaultMessage: 'To reserve your appointment, please enter your contact information:',
  },
  name: {
    id: 'AppointmentModal.name',
    defaultMessage: 'Name:',
  },
  phone: {
    id: 'AppointmentModal.phone',
    defaultMessage: 'Phone:',
  },
  close: {
    id: 'AppointmentModal.close',
    defaultMessage: 'Close',
  },
});
