/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import styled from 'styled-components';
import ImageGallery from '../ImageGallery';

const GalleryWrapper = styled.div`
  margin: 0px auto;
  margin-bottom: 6%;
  width: 85%;
  display: flex;
  justify-content: center;

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 700ms;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

class index extends Component {
  render() {
    return (
      <GalleryWrapper>
        <ImageGallery photos={this.props.photos} />
      </GalleryWrapper>
    );
  }
}

export default index;
